import React, { useState, useRef } from "react";
import styles from "./Login.module.css";
import registerStyles from "../Register/Register.module.css";
import { Link, useSearchParams } from "react-router-dom";
import email from "../../assets/email-icon.svg";
import password from "../../assets/password-icon.svg";
import Alert from "../../components/Alert";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import imgLoading from "../../assets/loading.gif";
import { useDispatch } from "react-redux";
import LoaderSecondary from "../LoaderSecondary";
import { userLogin } from "../../utils/api";

import classNames from "classnames";
import { Button } from "antd";
import FormGroup from "../FormGroup";

const Login = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [progress, setProgress] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProgress(true);

    if (formData.email.length === 0 || formData.password.length === 0) {
      setOpen(true);
      setSeverity("error");
      setMessage("Please fill all fields!");
      setProgress(false);
      return;
    }

    const data = await userLogin(JSON.stringify(formData));

    if (data.status === "success") {
      event.preventDefault();
      setMessage(`${data.message}`);
      setProgress(false);
      const token = data.access_token;
      localStorage.setItem("username", data.username);
      localStorage.setItem("yourBroker", data.brokerName);
      localStorage.setItem("yourAgent", data.agentName);
      localStorage.setItem("token", token);
      const decodedToken = jwtDecode(data.access_token);
      localStorage.setItem("userRole", decodedToken.UserInfo.roles);
      dispatch({
        type: "userInfoRole",
        userRole: decodedToken.UserInfo.roles,
      });

      dispatch({
        type: "SET_KYC",
        kyc: data.kyc,
      });

      if (decodedToken.UserInfo.roles === "admin") {
        navigate("/admin");
      } else {
        navigate("/project?id=1");
      }
    } else if (data.status === "fail") {
      event.preventDefault();
      setOpen(true);
      setSeverity("error");
      setMessage(`${data.message}`);
      setProgress(false);
    }
  };

  const inputRefs = [useRef(null), useRef(null)];

  const handleFocus = (index) => {
    inputRefs[index].current.classList.add(styles.focused);
  };

  const handleBlur = (index) => {
    inputRefs[index].current.classList.remove(styles.focused);
  };

  return (
    <>
      <div className="py-4"></div>
      <div className={registerStyles.container}>
        <div className={registerStyles.t1}>WELCOME TO WEFRACTION</div>
        <div className={styles.wrapper}>
          <div className={registerStyles.t2}>Log In</div>
          <form onSubmit={handleSubmit} className={registerStyles.form}>
            <FormGroup
              type="email"
              state={formData.email}
              handleChange={handleChange}
              label="Email"
              name="email"
              required
            />

            <FormGroup
              type="password"
              state={formData.password}
              handleChange={handleChange}
              label="Password"
              name="password"
              required
            />
            <div className={styles.forgotPasswordContainer}>
              <Link to="/forgot-password" className={styles.forgotPassword}>
                Forgot Password?
              </Link>
            </div>
            <button
              type="submit"
              disabled={progress}
              className={registerStyles.btnCreate}
            >
              LOGIN
              {progress && <LoaderSecondary />}
            </button>
            <div className={registerStyles.orLogIn}>
              <span>
                DON'T HAVE AN ACCOUNT?{"  "}
                <span
                  className={registerStyles.green}
                  onClick={() => navigate("/register")}
                >
                  SIGN UP HERE
                </span>
              </span>
            </div>
          </form>
        </div>
      </div>
      <div>
        <Alert
          message={message}
          open={open}
          setOpen={setOpen}
          severity={severity}
        ></Alert>
      </div>
    </>
  );
};

export default Login;
