import React, { useEffect, useState } from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import jwtDecode from "jwt-decode";
import Loader from "../Loader";
import axios from "axios";
import axiosInstance from "../../Axios/AxiosInstance";
import { Col, Container, Row } from "react-bootstrap";

export default function KYCPage() {
  let token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      let jwt = jwtDecode(token);
      let id = jwt.UserInfo.id;
      getKYCToken(id);
    }
  }, [token]);

  const [kyctoken, setKyctoken] = useState(null);
  return (
    <>
      <div className="mt-5"></div>
      <div className="mt-5"></div>
      <div className="text-center text-white">
        <Row>
          <Col lg="6" className="mx-auto">
            <h1
              style={{
                fontFamily: "Bebas Neue",
              }}
            >
              Hello {localStorage.getItem("username")}{" "}
            </h1>
            <h1
              style={{
                fontFamily: "Bebas Neue",
              }}
            >
              Let's Get You Verified!
            </h1>
            <h5
              style={{
                fontFamily: "Standerd",
              }}
            >
              Verification (or KYC/AML) is a process we go through with all our
              users in order to protect you and make sure all your documentation
              is in order. 
              <p
                style={{
                  fontSize: "16px",
                  marginTop: "10px",
                }}
              >
                KYC/AML is done by our third-party provider Sumsub
              </p>
            </h5>
          </Col>
        </Row>
      </div>

      {kyctoken ? (
        <SumsubWebSdk
          accessToken={kyctoken}
          expirationHandler={() => {
            return getKYCToken();
          }}
          config={{}}
          options={{ addViewportTag: false, adaptIframeHeight: true }}
          onMessage={(e) => {
            console.log(e);
            console.log("message");
          }}
          onError={(e) => {
            console.log("error sumsub");
            console.log(e);
          }}
        />

      ) : (
        <div
          className="local-bootstrap"
          style={{
            minHeight: "50vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className="text-center">
            <Loader width={100} height={100} />
          </div>
        </div>
      )}
      <Container fluid className="p-0">
        <img style={{ width: "100%"}} className="mx-auto" src={require("../../assets/banner.png")} alt="" />
      </Container>
    </>   
  );

  async function getKYCToken(id) {
    console.log("id", id);
    let res = await axiosInstance.post("/createAccessToken/" + id);
    console.log("res.data", res.data);
    if (res?.data) {
      setKyctoken(res.data);
      return res.data;
    }
  }
}
