import React, { useEffect, useState } from "react";
import styles from "./AssetInvestor.module.css";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { getInvestorAssets } from "../../utils/api";
import Loader from "../Loader";
import { Tag } from "antd";

const AssetInvestor = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const isOpen = useSelector((state) => state.isOpen);
  var Css = styles.menuOpen;
  var Css1 = styles.menuOpen1;
  if (isOpen == false) {
    Css = styles.menuClose;
    Css1 = styles.menuClose1;
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const result = await getInvestorAssets();
    console.log("investor assets");
    console.log(result);

    if (result.status === "success") {
      setLoading(false);
      let fixedData = GroupTokens(result.data);
      setData(fixedData);
    } else if (result.status === "fail") {
      setLoading(false);
      setData([]);
    }
  };

  return (
    <div className={classNames(styles.container, Css)}>
      <div className={styles.t1}>Your Assets</div>
      <div className={styles.wrapper}>
        {isLoading && <Loader />}

        {data.map((item, index) => (
          <div className={styles.row} key={item.id}>
            <img
            style={{
              objectFit: "contain",
            }}
              src={item?.nft_image}
              alt="building-icon"
              className={styles.building_image}
            />
            <div className={styles.details}>
              <div className={styles.title}>BUILDING NAME</div>
              <div className={styles.location}>
                {item.building_name} / {item.city_name}
              </div>
            </div>
            <div className={styles.token}>TOKENS OWNED</div>
            <div className={styles.token_area} style={{ overflowY: "auto" }}>
              {item.Tokens.map((token, index) => (
                <Tag color="#00a76a">{token}</Tag>
              ))}
            </div>
            <button className={styles.sell_btn}>SELL</button>
          </div>
        ))}
      </div>
    </div>
  );

  function GroupTokens(data) {
    let res = {};
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if (typeof element.developer_brochure === "string") {
        element.developer_brochure = JSON.parse(element.developer_brochure);
      }

      res[element.id] = {
        id: element.id,
        building_name: element.building_name,
        city_name: element.city_name,
        area: element.area,
        developer_brochure: element.developer_brochure,
        nft_image: element.nft_image,
        Tokens: [...element.Tokens],
      };
      // console.log(element);
      // console.log(element.token_id);
      // console.log(element.realEstatePropertyId);
      // if (res.hasOwnProperty(element.realEstatePropertyId)) {
      //   res[element.realEstatePropertyId].Tokens.push(element.token_id);
      // } else {
      //   res[element.realEstatePropertyId] = {
      //     building_name: element.building_name,
      //     area: element.area,
      //     city_name: element.city_name,
      //     id: element.id,
      //     Tokens: [element.token_id],
      //     developer_brochure: element.developer_brochure,
      //     realEstatePropertyId: element.realEstatePropertyId,
      //   };
      // }
    }
    console.log(Object.values(res));
    return Object.values(res);
  }
};

export default AssetInvestor;
