import axiosInstance from "./AxiosInstance";

const axiosInterceptors = {
  request: axiosInstance.interceptors.request.use(
    (req) => {
      const token = localStorage.getItem("token");
      if (token) {
        req.headers.Authorization = `Bearer ${token}`;
      }
      return req;
    },
    (error) => {
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
  ),
  response: null,
};

export default axiosInterceptors;
