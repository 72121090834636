import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  ConfigProvider,
  Input,
  Popconfirm,
  Space,
  Table,
  theme,
} from "antd";
import { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import axiosInstance from "../../Axios/AxiosInstance";
import fileDownload from "js-file-download";

export default function BankTranferTable() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [BankTransfersData, setBankTransfersData] = useState([]);
  const [verifying, setVerifying] = useState(false);
  const [loading, setLoading] = useState(false);

  let handleAcceptTransfer = (data) => {
    console.log("verifying ", data);

    setVerifying(true);
    axiosInstance
      .post("/bank_transfer", {
        token_bank_transfer_id: data.token_bank_transfer_id,
        bank_transfer_id: data.id,
      })
      .then((res) => {
        if (res.data.status === "success") {
          setBankTransfersData(
            BankTransfersData.filter((item) => item.key !== data.key)
          );
          console.log(res.data);
        } else {
          console.log("error");
          console.log(res);
          alert("Something Went Wrong!");
        }
        setVerifying(false);
      })
      .catch((error) => {
        console.error(error);
        setVerifying(false);
      });
  };

  let handleDeclineTransfer = (data) => {
    console.log("verifying ", data);

    setVerifying(true);
    axiosInstance
      .delete("/bank_transfer/" + data.token_bank_transfer_id)
      .then((res) => {
        if (res.data.status === "success") {
          let index = BankTransfersData.indexOf(data);
          if (index === -1) return;
          setBankTransfersData(
            BankTransfersData.filter((item) => item.key !== data.key)
          );
        } else {
          console.log("error");
          console.log(res);
          alert("Something Went Wrong!");
        }
        setVerifying(false);
      })
      .catch((error) => {
        console.error(error);
        setVerifying(false);
      });
  };

  useEffect(() => {
    LoadBankTranfers();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Investor Email",
      dataIndex: "investor_email",
      key: "investor_email",
      sorter: (a, b) => a.investor_email.localeCompare(b.investor_email),
      width: "20%",
      ...getColumnSearchProps("investor_email"),
    },
    {
      title: "Broker Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      width: "20%",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Bank Document",
      dataIndex: "document",
      key: "document",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Token ID",
      dataIndex: "token_id",
      key: "token_id",
      sorter: (a, b) =>
        a.token_id.toString().localeCompare(b.token_id.toString()),
      ...getColumnSearchProps("token_id"),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (data) => (
        <>
          <ConfigProvider theme={{ algorithm: theme.defaultAlgorithm }}>
            <Popconfirm
              disabled={verifying}
              placement="top"
              title="NFT Transfer"
              description="Are you Sure ?"
              onConfirm={() => {
                handleAcceptTransfer(data);
              }}
              okText="Yes"
              cancelText="No"
            >
              <button
                disabled={verifying}
                className="btn btn-outline-success rounded-0 px-4 py-2 mx-1"
              >
                ACCEPT
              </button>
            </Popconfirm>
            <Popconfirm
              disabled={verifying}
              placement="top"
              title="Decline Transfer ?"
              description="Are you Sure ?"
              onConfirm={() => {
                handleDeclineTransfer(data);
              }}
              okText="Yes"
              cancelText="No"
            >
              <button
                disabled={verifying}
                className="btn btn-outline-danger rounded-0 px-4 py-2 mx-1"
              >
                DECLINE
              </button>
            </Popconfirm>
          </ConfigProvider>
        </>
      ),
    },
  ];
  return (
    <>
      <button
        className="btn btn-success my-3"
        disabled={loading}
        onClick={() => {
          LoadBankTranfers();
        }}
      >
        Refresh
      </button>
      <Table
        loading={loading}
        columns={columns}
        dataSource={BankTransfersData}
      />
    </>
  );

  async function LoadBankTranfers() {
    setLoading(true);
    axiosInstance
      .get("/bank_transfer")
      .then((res) => {
        console.log("banks");
        console.log(res);
        if (res.data.message) {
          setBankTransfersData([]);
          setLoading(false);
          return;
        }

        setBankTransfersData(
          res.data.data.map((x) => ({
            id: x.id,
            account_id: x.accountId,
            email: x.email,
            key: x.id + "" + x.token_bank_transfer_id,
            investor_email: x.investor_email,
            document: (
              <div className="text-center">
                <button
                  className="btn btn-outline-success rounded-0"
                  onClick={() => {
                    axiosInstance({
                      url: x.upload_proof[0], //your url
                      method: "GET",
                      responseType: "blob", // important
                      onDownloadProgress: (progressEvent) => {},
                    }).then((response) => {
                      fileDownload(
                        response.data,
                        x.upload_proof[0].substring(
                          x.upload_proof[0].lastIndexOf("/") + 1
                        )
                      );
                    });
                  }}
                >
                  Download
                </button>
              </div>
            ),
            token_id: x.token_id,
            token_bank_transfer_id: x.token_bank_transfer_id,
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }
}
