import React, { useState } from "react";
import styles from "./Register/Register.module.css";
import eyeIcon from "../assets/eye.png";
import eyeCrossedIcon from "../assets/eyeCrossed.png";

function FormGroup({
  type = "text",
  state,
  handleChange,
  label,
  name,
  required,
}) {
  const [typeState, setTypeState] = useState(type);

  const toggleType = () => {
    if (typeState === "password") {
      setTypeState("text");
    } else {
      setTypeState("password");
    }
  };

  return (
    <div className={`${styles.formGroup} ${state && styles.filled}`}>
      <input
        type={typeState}
        value={state}
        onChange={handleChange}
        name={name}
        className={styles.customInput}
        required={required}
      />
      <label className={state && styles.filled} htmlFor={name}>
        {label + (required ? "*" : "")}
      </label>

      {type === "password" && (
        <>
          <img
            src={typeState === "password" ? eyeCrossedIcon : eyeIcon}
            alt=""
            className={styles.passwordIcon}
            onClick={toggleType}
          />
        </>
      )}
    </div>
  );
}

export default FormGroup;
