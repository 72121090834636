import React, { useState } from "react";
import TrashIcon from "../../assets/TrashIcon.png";
import styles from "./DeleteLink.module.css";
import { deleteLinks } from "../../utils/api";
import LoadingIcon from "../../assets/loading.gif";

export const DeleteLink = ({ id, fetchData }) => {
  const [progress, setProgress] = useState(false);

  const handleDeleteLink = async (e) => {
    e.preventDefault();
    setProgress(true);

    const data = await deleteLinks(id);

    if (data.status === "success") {
      fetchData();
      setProgress(false);
    }
  };

  return (
    <button className={styles.view_button} onClick={handleDeleteLink}>
      {progress ? (
        <img src={LoadingIcon} alt="loading" className={styles.imgLoading} />
      ) : (
        <img src={TrashIcon} alt="trash-icon" />
      )}
    </button>
  );
};
