import React, { useRef, useEffect, useState } from "react";
import useOutsideDetector from "../../utils/useOutsideDetector";
import styles from "./ViewModal.module.css";
import icon1 from "../../assets/transaction.png";
import icon2 from "../../assets/Clicks.png";
import icon3 from "../../assets/sign-up.png";
import icon4 from "../../assets/Revenue.png";
import Loader from "../Loader";
import { getViewModalAffiliateLinks } from "../../utils/api";

const ViewModal = ({ item, onClose, id }) => {
  const outSideRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [table, setTable] = useState([]);
  const [generatedRevenue, setGeneratedRevenue] = useState(0);

  useOutsideDetector(outSideRef, () => {
    onClose();
  });

  const testIdData = {
    id: "testId",
    username: "test",
    
  }

  useEffect(() => {
    console.log("id in useffect", id);
    fetchData(id);
  }, [id]);

  const fetchData = async (id) => {
    setIsLoading(true);
    const result = await getViewModalAffiliateLinks(id);
    console.log("id", id);

    if (result.status === "success") {
      setData(result.data);
      setTable(result?.data?.table);
      const revenue = result?.data?.table?.reduce(
        (acc, item) => acc + item?.price,
        0
      );
      setGeneratedRevenue(revenue);
      setIsLoading(false);
    } else if (result.status === "fail") {
      console.log("error", result.message);
      setIsLoading(false);
      setData([]);
    }
  };

  return (
    <div className={styles.overlay}>
      <div ref={outSideRef} className={styles.container}>
        <div className={styles.section1}>
          <div className={styles.box}>
            <div className={styles.title}>
              <img src={icon1} alt="icon" className={styles.icon} />
              <div>TRANSACTIONS GENERATED</div>
            </div>
            <div className={styles.value}>{table.length}</div>
          </div>
          <div className={styles.grid}>
            <div className={styles.box}>
              <div className={styles.title}>
                <img src={icon2} alt="grid" />
                <div>CLICKS ON YOUR LINKS</div>
              </div>
              <div className={styles.value}>
                {/* {table[0] ? table[0]?.click : 0} */}
                {item.click ? item.click : 0}
              </div>
            </div>
          </div>
          <div className={styles.grid}>
            <div className={styles.box}>
              <div className={styles.title}>
                <img src={icon3} alt="grid" />
                <div>SIGN UP NUMBER</div>
              </div>
              <div
                style={{ justifyContent: "space-between" }}
                className={styles.value}
              >
                <p style={{ marginLeft: 20 }}>Total</p>
                <p style={{ marginRight: 20 }}>
                  {/* {data?.signUp ? data?.signUp : 0} */}
                  {data?.signUp ? data?.signUp : 0}
                </p>
              </div>
              <div
                style={{ justifyContent: "space-between" }}
                className={styles.value}
              >
                <p style={{ marginLeft: 20 }}> KYC</p>
                <p style={{ marginRight: 20 }}>
                  {true && console.log(table)}
                  {data?.kyc ? data?.kyc : 0}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.grid}>
            <div className={styles.box}>
              <div className={styles.title}>
                <img src={icon4} alt="grid" />
                <div>REVENUE GENERATED</div>
              </div>
              <div className={styles.value}>{generatedRevenue}</div>
            </div>
          </div>
        </div>
        <table>
          <thead className={styles.head}>
            <tr>
              <th className={styles.th}>NFT</th>
              <th className={styles.th}>Price</th>
              <th className={styles.th}>Buyer</th>
              <th className={styles.th}>Rate (%)</th>
              <th className={styles.th}>Commission</th>
            </tr>
          </thead>
          <tbody>
            {table?.map((item, index) => (
              <tr className={styles.tr_view}>
                <td className={styles.tr_view}>{item.building_name}</td>
                <td className={styles.tr_view}>{item.price}</td>
                <td className={styles.tr_view}>{item.email}</td>
                <td className={styles.tr_view}>0</td>
                <td className={styles.tr_view}>0</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewModal;
