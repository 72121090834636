import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./VerifyKYC.scss";
import KYCTable from "./KYCTable";

export default function VerifyKYC() {
  return (
    <Container
      fluid
      style={{
        width: "100%",
      }}
    >
      <Row className="mt-5">
        <Col lg={9} className="mx-auto mt-5">
          <h1>KYC Confirmation</h1>
        </Col>
      </Row>
      <Row>
        <Col lg="9" className="mx-auto">
          <KYCTable />
        </Col>
      </Row>
    </Container>
  );
}
