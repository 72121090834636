import React, { useState, useRef, useEffect } from "react";
import styles from "../Register/Register.module.css";
import logo from "../../assets/WeFraction-Logo.svg";
import { Link, useSearchParams } from "react-router-dom";
import profile from "../../assets/profile-icon.svg";
import phone from "../../assets/phone-icon.svg";
import email from "../../assets/email-icon.svg";
import password from "../../assets/password-icon.svg";
import Alert from "../../components/Alert";
import jwtDecode from "jwt-decode";
import LoaderSecondary from "../LoaderSecondary";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userLogin, userRegister } from "../../utils/api";
import { Button } from "antd";
import { setCookie } from "../../utils/libs";
import axios from "axios";
import FormGroup from "../FormGroup";
import { CONSTANTS } from "../../utils/global";

const InvestorOnlyRegister = () => {
  const [userType, setUserType] = useState("investor");
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  let [searchParams] = useSearchParams();
  const [response, setResponse] = useState("");

  let id = searchParams.get("gid");

  useEffect(() => {
    if (id) {
      setCookie(id);
      axios
        .get(`${CONSTANTS.BASE_URL}/link_click/${id}`)
        .then((res) => {
          console.log(res);
          setResponse(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const [progress, setProgress] = useState(false);

  const userRole = useSelector((state) => state.userRole);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    password: "",
    // role: `${userType}`,
    role: "investor",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProgress(true);

    if (
      formData.first_name.length === 0 ||
      formData.email.length === 0 ||
      formData.password.length === 0
    ) {
      setOpen(true);
      setSeverity("error");
      setMessage("Please fill all fields!");
      setProgress(false);
      return;
    }

    // Confirm password validation
    if (formData.password !== confirmPassword) {
      setOpen(true);
      setSeverity("error");
      setMessage("Passwords do not match!");
      setProgress(false);
      return;
    }

    const data = await userRegister(JSON.stringify(formData));

    if (data.status === "success") {
      const data = await userLogin(JSON.stringify(formData));

      if (data.status === "success") {
        event.preventDefault();
        setMessage(`${data.message}`);
        setProgress(false);
        const token = data.access_token;
        localStorage.setItem("username", data.username);
        localStorage.setItem("yourBroker", data.brokerName);
        localStorage.setItem("yourAgent", data.agentName);
        localStorage.setItem("token", token);
        const decodedToken = jwtDecode(data.access_token);
        localStorage.setItem("userRole", decodedToken.UserInfo.roles);
        dispatch({
          type: "userInfoRole",
          userRole: decodedToken.UserInfo.roles,
        });

        dispatch({
          type: "SET_KYC",
          kyc: data.kyc,
        });

        if (decodedToken.UserInfo.roles === "admin") {
          navigate("/admin");
        } else {
          navigate("/project?id=1");
        }
      } else if (data.status === "fail") {
        event.preventDefault();
        setOpen(true);
        setSeverity("error");
        setMessage(`${data.message}`);
        setProgress(false);
      }
    } else if (data.status === "fail") {
      setOpen(true);
      setProgress(false);
      event.preventDefault();
      setSeverity("error");
      setMessage(`${data.message}`);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUserType = (event) => {
    setUserType(event.target.value);
  };

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handlePrivacy = (event) => {
    setIsChecked(event.target.checked);
  };

  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleFocus = (index) => {
    inputRefs[index].current.classList.add(styles.focused);
  };

  const handleBlur = (index) => {
    inputRefs[index].current.classList.remove(styles.focused);
  };

  return (
    <>
      <div className="py-4"></div>
      <div className={styles.container}>
        <div className={styles.t1}>SIGN UP TO WEFRACTION</div>
        <div className={styles.wrapper}>
          <div className={styles.t2}>CREATE AN ACCOUNT NOW!</div>
          <div className={styles.t3}>
            {!response ? "" : "Referred By " + response?.name}
          </div>
          <form className={styles.form} onSubmit={handleSubmit}>
            <FormGroup
              handleChange={handleChange}
              state={formData.first_name}
              label="First Name"
              name="first_name"
              required
            />
            <FormGroup
              handleChange={handleChange}
              state={formData.last_name}
              label="Last Name"
              name="last_name"
              required
            />
            <FormGroup
              handleChange={handleChange}
              state={formData.phone_number}
              label="Phone Number"
              name="phone_number"
              required
            />
            <FormGroup
              handleChange={handleChange}
              state={formData.email}
              label="Email"
              name="email"
              type="email"
              required
            />
            <FormGroup
              handleChange={handleChange}
              state={formData.password}
              type="password"
              label="Password"
              name="password"
              required
            />
            <FormGroup
              handleChange={handleChangeConfirmPassword}
              type="password"
              state={confirmPassword}
              label="Confirm Password"
              name="confirmPassword"
              required
            />

            <label className={styles.privacyContainer}>
              <input type="checkbox" required onChange={handlePrivacy} />I
              accept the Terms, Privacy Policy, and Cookie Policy.
            </label>

            <button
              type="submit"
              disabled={!isChecked || progress}
              className={styles.btnCreate}
            >
              Create Account
              {progress && <LoaderSecondary />}
            </button>
            <div className={styles.orLogIn}>
              <span>
                ALREADY HAVE AN ACCOUNT?{"  "}
                <span
                  className={styles.green}
                  onClick={() => navigate("/login")}
                >
                  LOGIN HERE
                </span>
              </span>
            </div>
          </form>
          {/* <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.f1}>
              <label htmlFor="firstname" className={styles.label1}>
                <img src={profile} alt="profile" className={styles.profile1} />
                <input
                  type="text"
                  id="firstname"
                  name="first_name"
                  placeholder="First Name"
                  required
                  value={formData.firstname}
                  onChange={handleChange}
                  ref={inputRefs[0]}
                  onFocus={() => handleFocus(0)}
                  onBlur={() => handleBlur(0)}
                />
              </label>

              <label htmlFor="lastname" className={styles.label1}>
                <img src={profile} alt="profile" className={styles.profile2} />
                <input
                  type="text"
                  id="lastname"
                  name="last_name"
                  placeholder="Last Name"
                  required
                  value={formData.lastname}
                  onChange={handleChange}
                  className={styles.lastName}
                  ref={inputRefs[1]}
                  onFocus={() => handleFocus(1)}
                  onBlur={() => handleBlur(1)}
                />
              </label>
            </div>

            <label htmlFor="phone" className={styles.label1}>
              <img src={phone} alt="profile" className={styles.profile1} />
              <input
                type="tel"
                id="phone"
                name="phone_number"
                placeholder="Phone Number"
                required
                value={formData.phone}
                onChange={handleChange}
                ref={inputRefs[2]}
                onFocus={() => handleFocus(2)}
                onBlur={() => handleBlur(2)}
              />
            </label>
            <label htmlFor="email" className={styles.label1}>
              <img src={email} alt="profile" className={styles.profile1} />
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                required
                value={formData.email}
                onChange={handleChange}
                ref={inputRefs[3]}
                onFocus={() => handleFocus(3)}
                onBlur={() => handleBlur(3)}
              />
            </label>

            <label htmlFor="password" className={styles.label1}>
              <img src={password} alt="profile" className={styles.profile1} />
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                required
                value={formData.password}
                onChange={handleChange}
                ref={inputRefs[4]}
                onFocus={() => handleFocus(4)}
                onBlur={() => handleBlur(4)}
              />
            </label>
            <label htmlFor="confirmPassword" className={styles.label1}>
              <img src={password} alt="profile" className={styles.profile1} />
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm Password"
                required
                value={confirmPassword}
                onChange={handleChangeConfirmPassword}
                ref={inputRefs[5]}
                onFocus={() => handleFocus(5)}
                onBlur={() => handleBlur(5)}
              />
            </label>
            <label className={styles.privacyBtn}>
              <input type="checkbox" required onChange={handlePrivacy} />I
              accept the Terms, Privacy Policy, and Cookie Policy.
            </label>

            <button
              type="submit"
              disabled={!isChecked || progress}
              className={styles.btnCreate}
            >
              Create Account
              {progress && <LoaderSecondary />}
            </button>
            <p className={styles.p1}>
              Already Have an Account?
              <Link to="/login" className={styles.l1}>
                Login In
              </Link>
            </p>
          </form> */}
        </div>
      </div>
      <div>
        <Alert
          message={message}
          open={open}
          setOpen={setOpen}
          severity={severity}
        ></Alert>
      </div>
    </>
  );
};

export default InvestorOnlyRegister;
