import React, { useState } from "react";
import Select from "react-select";
import {
  Button,
  ConfigProvider,
  Input,
  Space,
  Switch,
  Upload,
  theme,
} from "antd";
import { Container } from "@mui/material";
import { Col, Form, Row } from "react-bootstrap";
import CheckableTag from "antd/es/tag/CheckableTag";
import TextArea from "antd/es/input/TextArea";
import axiosInstance from "../../Axios/AxiosInstance";
import Loader from "../Loader";

export default function AdminSubmitProperty() {
  const [offplan, setOffplan] = useState(false);
  const [rented, setRented] = useState(false);
  const [loansPaymentsMortgages, setLoansPaymentsMortgages] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [loading, setLoading] = useState(false);

  const AmenitiesTags = [
    "Gym",
    "Concierge",
    "Building / Community Pool",
    "Sauna",
    "Jacuzzi",
    "Private Balcony",
    "Private Pool",
    "Sea View",
    "Canal View",
    "Downtown View",
    "Burj Khalifa View",
    "Other",
  ];
  const handleChangeAmenities = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const [titleDeed, setTitledeed] = useState([]);
  const handleChangeTitleDeed = (info) => {
    let newFileList = [...info.fileList];
    setTitledeed(newFileList);
  };

  const [developerBrochure, setDeveloperBrochure] = useState([]);
  const handleChangeDeveloperBrochure = (info) => {
    let newFileList = [...info.fileList];
    setDeveloperBrochure(newFileList);
  };

  const [AssetLayout, setAssetLayout] = useState([]);
  const handleChangeAssetLayout = (info) => {
    let newFileList = [...info.fileList];
    setAssetLayout(newFileList);
  };

  const [NFTImage, setNFTImage] = useState([]);
  const handleChangeNFTImage = (info) => {
    let newFileList = [...info.fileList];
    setNFTImage(newFileList);
  };

  const [DownloadableROI, setDownloadableROI] = useState([]);
  const handleChangeDownloadableROI = (info) => {
    let newFileList = [...info.fileList];
    setDownloadableROI(newFileList);
  };

  const SubmittedForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    let data = new FormData();
    data.append("building_name", e.target["building_name"].value);
    data.append("city_name", e.target["city_name"].value);
    data.append("area", e.target["area"].value);
    data.append("floor", e.target["floor"].value);
    data.append("unit_number", e.target["unit_number"].value);
    data.append("asset_type", e.target["asset_type"].value);
    data.append("size_of_asset_sqft", e.target["size_of_asset_sqft"].value);
    data.append("off_plan", offplan);
    data.append("number_of_beds", e.target["number_of_beds"].value);
    data.append("number_of_baths", e.target["number_of_baths"].value);
    let indexOfOther = selectedTags.indexOf("Other");
    if (indexOfOther !== -1) {
      let amenitiesTags = selectedTags;
      amenitiesTags.splice(indexOfOther, 1);
      data.append("extra_amenities", e.target["extra_amenities"].value);
    }
    data.append("amenities", JSON.stringify({ tags: selectedTags }));
    data.append(
      "recommended_sale_price",
      e.target["recommended_sale_price"].value
    );
    data.append("rented", rented);
    data.append("service_charges_sqft", e.target["service_charges_sqft"].value);
    appendFiles("title_deed", data, titleDeed);

    data.append("loans_payments_mortgages", loansPaymentsMortgages);
    data.append(
      "developer_marketing_material",
      e.target["developer_marketing_material"].value
    );
    appendFiles("developer_brochure", data, developerBrochure);
    appendFiles("asset_layout", data, AssetLayout);

    data.append("comment", e.target["comment"].value);
    data.append("accountId", e.target["accountId"].value);
    data.append("gross_yeald", e.target["gross_yeald"].value);
    data.append("expected_return", e.target["expected_return"].value);
    data.append("dividends", e.target["dividends"].value);
    data.append("dividends_payments", e.target["dividends_payments"].value);
    data.append("funding_value", e.target["funding_value"].value);
    data.append("minimum_investment", e.target["minimum_investment"].value);
    data.append("time_to_close", e.target["time_to_close"].value);
    data.append("number_of_tokens", e.target["number_of_tokens"].value);

    data.append("yearly_rent_value", e.target["yearly_rent_value"].value);
    data.append(
      "total_outstanding_amount",
      e.target["total_outstanding_amount"].value
    );
    appendFiles("nft_image", data, NFTImage);
    appendFiles("downloadable_roi", data, DownloadableROI);
    console.log(Array.from(data.entries()));

    let t = await axiosInstance.post("/ad/property", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    setLoading(false);
    console.log(t);
    window.location.reload();
  };

  const appendFiles = (name, data, files) => {
    for (let i = 0; i < files.length; i++) {
      const element = files[i].originFileObj;
      data.append(name, element);
    }
  };

  return (
    <ConfigProvider theme={{ token: { colorPrimary: "#00a76a" } }}>
      <Container className="p-5 mt-4" style={{ border: "4px #00a76a solid" }}>
        <Row>
          <Col className="mx-auto">
            <Form onSubmit={SubmittedForm}>
              <Space direction="vertical" style={{ width: "50%" }}>
                <span style={{ fontSize: 30 }}>Building Name</span>
                <Input
                  className="mb-5 adminPropertyInput"
                  size="large"
                  name="building_name"
                  required
                />

                <span style={{ fontSize: 30 }}>City</span>
                <Input
                  className="mb-5 adminPropertyInput"
                  size="large"
                  name="city_name"
                  required
                />

                <span style={{ fontSize: 30 }}>Area</span>
                <Input
                  className="mb-5 adminPropertyInput"
                  size="large"
                  name="area"
                  required
                />

                <span style={{ fontSize: 30 }}>Floor</span>
                <Input
                  className="mb-5 px-2 adminPropertyInput"
                  size="large"
                  name="floor"
                  required
                />

                <span style={{ fontSize: 30 }}>Unit Number</span>
                <Input
                  className="mb-5 adminPropertyInput"
                  size="large"
                  name="unit_number"
                  required
                />
                <span style={{ fontSize: 30 }}>Asset Type</span>
                <Select
                  className="mb-5 adminPropertyInputSelect"
                  defaultValue="select"
                  name="asset_type"
                  options={[
                    { value: "Apartment", label: "Apartment" },
                    { value: "Villa", label: "Villa" },
                    { value: "Townhouse", label: "Townhouse" },
                    { value: "Penthouse", label: "Penthouse" },
                    {
                      value: "Commercial (Office)",
                      label: "Commercial (Office)",
                    },
                    {
                      value: "Commercial (Shop)",
                      label: "Commercial (Shop)",
                    },
                  ]}
                />

                <span style={{ fontSize: 30 }}>Size Of Asset (sqft)</span>
                <Input
                  className="mb-5 px-2 adminPropertyInput"
                  size="large"
                  name="size_of_asset_sqft"
                  required
                />

                <span style={{ fontSize: 30 }}>Off Plan</span>
                <Switch
                  className="adminPropertySwitch mb-5"
                  onChange={(checked, e) => {
                    setOffplan(checked);
                  }}
                />

                <span style={{ fontSize: 30 }}>Number Of Beds</span>
                <Select
                  className="mb-5 adminPropertyInputSelect"
                  defaultValue="select"
                  name="number_of_beds"
                  options={[
                    { value: "studio", label: "studio" },
                    { value: "1 bed", label: "1 bed" },
                    { value: "2 bed", label: "2 bed" },
                    { value: "3 bed", label: "3 bed" },
                    { value: "4 bed", label: "4 bed" },
                    { value: "5 bed", label: "5 bed" },
                  ]}
                />

                <span style={{ fontSize: 30 }}>Number Of Baths</span>
                <Select
                  className="mb-5 adminPropertyInputSelect"
                  defaultValue="select"
                  name="number_of_baths"
                  options={[
                    { value: "1 bath", label: "1 bath" },
                    { value: "2 baths", label: "2 baths" },
                    { value: "3 baths", label: "3 baths" },
                    { value: "4 baths", label: "4 baths" },
                    { value: "5 baths", label: "5 baths" },
                    { value: "6 baths", label: "6 baths" },
                  ]}
                />

                <span style={{ fontSize: 30 }}>Amenities</span>
                <Space size={[5, 5]} wrap>
                  {AmenitiesTags.map((tag) => (
                    <CheckableTag
                      key={tag}
                      checked={selectedTags.includes(tag)}
                      onChange={(checked) =>
                        handleChangeAmenities(tag, checked)
                      }
                    >
                      {tag}
                    </CheckableTag>
                  ))}
                </Space>
                <span style={{ fontSize: 30 }}>Extra Amenities</span>
                <Input
                  disabled={selectedTags.indexOf("Other") === -1}
                  className="mb-5 adminPropertyInput"
                  size="large"
                  name="extra_amenities"
                  required
                />

                <span style={{ fontSize: 30 }}>Recommended Sale Price</span>
                <Input
                  className="mb-5 px-2 adminPropertyInput"
                  size="large"
                  name="recommended_sale_price"
                  required
                />

                <span style={{ fontSize: 30 }}>Rented</span>
                <Switch
                  onChange={(checked, e) => {
                    setRented(checked);
                  }}
                  className="adminPropertySwitch mb-5"
                />

                <span style={{ fontSize: 30 }}>Yearly Rent Value</span>
                <Input
                  disabled={!rented}
                  className="mb-5 px-2 adminPropertyInput"
                  size="large"
                  name="yearly_rent_value"
                  required
                />
                <span style={{ fontSize: 30 }}>Service Charges (/Sqft)</span>
                <Input
                  className="mb-5 px-2 adminPropertyInput"
                  size="large"
                  name="service_charges_sqft"
                  required
                />

                <span style={{ fontSize: 30 }}>Title Deed (Upload Image)</span>
                <Upload
                  listType="picture"
                  fileList={titleDeed}
                  customRequest={dummyRequest}
                  onChange={handleChangeTitleDeed}
                >
                  <Button>Click to Upload</Button>
                </Upload>
                <div className="my-5"></div>

                <span style={{ fontSize: 30 }}>
                  Are there any Loans / Payments / Mortgages attached to the
                  property ?
                </span>
                <Switch
                  onChange={(checked, e) => {
                    console.log(checked);
                    setLoansPaymentsMortgages(checked);
                  }}
                  className="adminPropertySwitch mb-5"
                />

                <span style={{ fontSize: 30 }}>Total Outstanding Amount</span>
                <Input
                  disabled={!loansPaymentsMortgages}
                  className="mb-5 px-2 adminPropertyInput"
                  size="large"
                  name="total_outstanding_amount"
                  required
                />

                <span style={{ fontSize: 30 }}>Developer Brochure</span>
                <Upload
                  multiple
                  listType="picture"
                  fileList={developerBrochure}
                  customRequest={dummyRequest}
                  onChange={handleChangeDeveloperBrochure}
                >
                  <Button>Click to Upload</Button>
                </Upload>
                <div className="my-5"></div>

                <span style={{ fontSize: 30 }}>Asset Layout (Plans)</span>
                <Upload
                  multiple
                  listType="picture"
                  fileList={AssetLayout}
                  customRequest={dummyRequest}
                  onChange={handleChangeAssetLayout}
                >
                  <Button>Click to Upload</Button>
                </Upload>
                <div className="my-5"></div>

                <span style={{ fontSize: 30 }}>
                  Anything else you would like us to know that can be helpful in
                  promoting the asset ?
                </span>
                <TextArea className="adminPropertyInput" rows={4} name="comment" />

                <span style={{ fontSize: 30 }}>Broker ID</span>
                <Input
                  className="mb-5 px-2 adminPropertyInput"
                  size="large"
                  name="accountId"
                  required
                />

                <span style={{ fontSize: 30 }}>Gross YEALD</span>
                <Input
                  className="mb-5 px-2 adminPropertyInput"
                  size="large"
                  name="gross_yeald"
                  required
                />

                <span style={{ fontSize: 30 }}>Expected Return</span>
                <Input
                  className="mb-5 px-2 adminPropertyInput"
                  size="large"
                  name="expected_return"
                  required
                />

                <span style={{ fontSize: 30 }}>Dividends</span>
                <Input
                  className="mb-5 px-2 adminPropertyInput"
                  size="large"
                  name="dividends"
                  required
                />

                <span style={{ fontSize: 30 }}>Dividends Payment</span>
                <Input
                  className="mb-5 px-2 adminPropertyInput"
                  size="large"
                  name="dividends_payments"
                  required
                />

                <span style={{ fontSize: 30 }}>Funding Value</span>
                <Input
                  className="mb-5 px-2 adminPropertyInput"
                  size="large"
                  name="funding_value"
                  required
                />

                <span style={{ fontSize: 30 }}>Minimum Investment</span>
                <Input
                  className="mb-5 px-2 adminPropertyInput"
                  size="large"
                  name="minimum_investment"
                  required
                />

                <span style={{ fontSize: 30 }}>Time to close</span>
                <Input
                  className="mb-5 px-2 adminPropertyInput"
                  size="large"
                  name="time_to_close"
                  required
                />

                <span style={{ fontSize: 30 }}>
                  Number Of Tokens For Smart Contract*
                </span>
                <Input
                  className="mb-5 px-2 adminPropertyInput"
                  size="large"
                  name="number_of_tokens"
                  required
                />

                <span style={{ fontSize: 30 }}>NFT Image</span>
                <Upload
                  listType="picture"
                  fileList={NFTImage}
                  customRequest={dummyRequest}
                  onChange={handleChangeNFTImage}
                  maxCount={1}
                >
                  <Button>Click to Upload</Button>
                </Upload>
                <div className="my-5"></div>
                <span style={{ fontSize: 30 }}>Downloadable ROI Files</span>
                <Upload
                  multiple
                  listType="picture"
                  fileList={DownloadableROI}
                  customRequest={dummyRequest}
                  onChange={handleChangeDownloadableROI}
                  maxCount={3}
                >
                  <Button>Click to Upload</Button>
                </Upload>
                <div className="my-5"></div>

                <span style={{ fontSize: 30 }}>Cell Number</span>
                <Input
                  className="mb-5 px-2 adminPropertyInput"
                  size="large"
                  name="developer_marketing_material"
                  required
                />

                <button
                  disabled={loading}
                  className=" bg-green text-white border-0 px-5 py-3 mt-5"
                  style={{ fontSize: 30 }}
                  type="submit"
                >
                  Submit Property
                  {loading ? <Loader /> : ""}
                </button>
                {loading ? <p>This may take a while...please wait</p> : ""}
              </Space>
            </Form>
          </Col>
        </Row>
      </Container>
    </ConfigProvider>
  );
}
