import React from "react";
import styled from "styled-components/macro";

function ChoiceSelector({ title, state, handleState }) {
  return (
    <>
      <Container>
        <Title>{title}</Title>
        <ChoiceWrapper>
          <Choice isActive={state === true} onClick={handleState}>
            Yes
          </Choice>
          <Choice isActive={state === false} onClick={handleState}>
            No
          </Choice>
        </ChoiceWrapper>
      </Container>
    </>
  );
}

export default ChoiceSelector;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  height: 50px;
  margin-top: 15px;
  font-family: "Standerd" !important;
  text-transform: uppercase;
`;

const Title = styled.span`
  font-weight: 100;
  font-size: 22px;
  color: #b4b4b4;
  padding: 0;
  margin: 0;
  margin-right: 25px;
  max-width: 50%;
  flex-grow: 0;
`;

const ChoiceWrapper = styled.div`
  display: flex;
  gap: 25px;
`;

const Choice = styled.div`
  height: 50px;
  display: flex;
  font-family: "Bebas Neue" !important;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  cursor: pointer;
  border: ${({ isActive }) => (!isActive ? "1px solid #B4B4B4" : "none")};
  background-color: ${({ isActive }) => (isActive ? "#00a76a" : "transparent")};
  color: ${({ isActive }) => (isActive ? "#fff" : "#B4B4B4")};
  font-weight: 300;
  font-size: 22px;
  opacity: ${({ isActive }) => (isActive ? "1" : "0.35")};
  transition: all 0.3s ease-in-out;
  user-select: none;
`;
