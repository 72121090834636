import React, { useState } from "react";
import styles from "./GenerateLink.module.css";
import LoadingIcon from "../../assets/loading.gif";
import { generateLinks } from "../../utils/api";

export const GenerateLink = ({ generate_link_form, username, setUsername, fetchData }) => {
  const [progress, setProgress] = useState(false);

  const handleGenerateLink = async (e) => {
    e.preventDefault();
    setProgress(true);

    if (username.length === 0) {
      return null;
    }

    // Send formData to the server
    console.log(generate_link_form);

    const data = await generateLinks(generate_link_form);

    if (data.status == "success") {
      fetchData();
      setProgress(false);
      setUsername("");
    }

    console.log(data);
  };

  return (
    <button
      disabled={username.length === 0}
      className={styles.generate_link_btn}
      onClick={handleGenerateLink}
    >
      Generate
      {progress && <img src={LoadingIcon} alt="loading" className={styles.imgLoading} />}
    </button>
  );
};
