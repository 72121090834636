import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import NavigationTabs from "../Sidebar/NavigationTabs";
import Header from "../Header/Header";
import { Outlet } from "react-router-dom";
import useWindowDimensions from "../../utils/useWindowDimensions";

export default function SideBarLayout() {
  const { height, width } = useWindowDimensions();
  return (
    <>
      {width < 777 ? <NavigationTabs /> : <Sidebar />}

      {/* <Header /> */}
      <Outlet />
    </>
  );
}
