import React from "react";
import { ColorRing } from "react-loader-spinner";

export default function LoaderSecondary({ height = 30, width = 30 }) {
  return (
    <ColorRing
      visible={true}
      height={height}
      width={width}
      ariaLabel="blocks-loading"
      wrapperStyle={{}}
      wrapperClass="blocks-wrapper"
      colors={["#151515", "#151515", "#151515", "#151515", "#151515"]}
    />
  );
}
