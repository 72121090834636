import Cookies from "js-cookie";

export const getCookie = () => {
  if (Cookies.get("_gid")) {
    return Cookies.get("_gid");
  } else {
    return null;
  }
};
export const setCookie = (value) => {
  Cookies.set("_gid", value);
};
