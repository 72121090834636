import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
const Protected = ({ allowedRole }) => {
  const userRole = useSelector((state) => state.userRole);

  if (userRole !== allowedRole) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
};
export default Protected;
