import React, { useState } from "react";
import styles from "./Table.module.css";
import ViewModal from "./ViewModal";
import { GenerateLink } from "./GenerateLink";
import EyeIcon from "../../assets/Eye-Icon.png";
import ClipboardIcon from "../../assets/ClipboardIcon.png";
import { useSelector } from "react-redux";
import { DeleteLink } from "./DeleteLink";
import classNames from "classnames";
import Loader from "../Loader";
import { Link } from "react-router-dom";
import { CONSTANTS } from "../../utils/global";

const Table = ({ AffiliateLinks, message, fetchData, isLoading }) => {
  const [selectedRow, setSelectedRow] = useState(null);

  const isOpen = useSelector((state) => state.isOpen);

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [username, setUsername] = useState("");

  const data = JSON.stringify({
    username: username,
  });
  const copyLink = (link) => {
    // create a temporary input element
    const input = document.createElement("input");
    input.value = link;
    document.body.appendChild(input);

    // select the input element and copy the text
    input.select();
    document.execCommand("copy");

    // remove the temporary input element
    document.body.removeChild(input);
  };
  // console.log(data);

  const handleViewClick = (item) => {
    setSelectedItem(item);
    setIsViewModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsViewModalOpen(false);
  };

  const handleRowClick = (id, item) => {
    setSelectedRow(id);
    setSelectedItem(item);
  };

  // console.log(AffiliateLink);

  var Css = styles.menuOpen;
  var Css1 = styles.menuOpen1;
  if (isOpen == false) {
    Css = styles.menuClose;
    Css1 = styles.menuClose1;
  }

  return (
    <div className={classNames(styles.tableContainer, Css)}>
      <table className={styles.table}>
        <thead className={styles.head}>
          <tr>
            <th>Name</th>
            <th style={{ borderLeft: "0.2rem solid #151515" }}>Link</th>
            <th style={{ borderLeft: "0.2rem solid #151515" }}>Action</th>
          </tr>
        </thead>

        <tbody className={styles.body}>
          {AffiliateLinks === undefined ? (
            <div className={styles.errMessage}>{message}</div>
          ) : (
            AffiliateLinks.map((item, index) => (
              <tr
                key={item.id}
                onClick={() => handleRowClick(item.id, item)}
                className={selectedRow === item.id ? `${styles.selected}` : ""}
                style={{
                  backgroundColor: index % 2 === 0 ? "#1a1a1a" : "#151515",
                }}
              >
                <td>{item.username}</td>
                <td>
                  <Link
                    to={`${CONSTANTS.FRONTEND_URL}/register?gid=${item.link}`}
                    className={styles.link}
                    target="_blank"
                  >
                    {`${CONSTANTS.FRONTEND_URL}/register?gid=${item.link}`}
                  </Link>
                </td>
                <td
                  style={{
                    // padding: "0",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: "2rem",
                  }}
                >
                  <button
                    className={styles.view_button}
                    onClick={() => handleViewClick(item)}
                  >
                    <img src={EyeIcon} alt="eye-icon" />
                  </button>

                  <button
                    onClick={() =>
                      copyLink(
                        `${CONSTANTS.FRONTEND_URL}/register?gid=${item.link}`
                      )
                    }
                    className={styles.view_button}
                  >
                    <img src={ClipboardIcon} alt="clipboard-icon" />
                  </button>
                  <DeleteLink id={item.id} fetchData={fetchData} />
                </td>
              </tr>
            ))
          )}
          {isViewModalOpen && (
            <ViewModal
              item={selectedItem}
              onClose={handleCloseModal}
              id={selectedItem.id}
            />
          )}
          {isLoading === true && (
            <div className={styles.loading}>
              <Loader />
            </div>
          )}

          <tr
            style={{
              backgroundColor: "#151515",
            }}
          >
            <td className={styles.td_username}>
              <input
                type="text"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Name"
              />
            </td>
            <td className={styles.td_link}>Link Will be generated here</td>
            <td>
              <GenerateLink
                generate_link_form={data}
                username={username}
                setUsername={setUsername}
                fetchData={fetchData}
              />
            </td>
          </tr>
        </tbody>
      </table>
      {/* <div className={styles.add_link_btn_container}>
        <AddLink linkData={setLink} />
      </div> */}
    </div>
  );
};

export default Table;
