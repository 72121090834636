import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProfileIcon from "../../assets/ProfileIcon.svg";
import UpIcon from "../../assets/up.svg";
import DownIcon from "../../assets/down.svg";
import classnames from "classnames";
import Cookies from "js-cookie";
import { Col, ConfigProvider, Popover, QRCode, theme } from "antd";
import axiosInstance from "../../Axios/AxiosInstance";
import jwtDecode from "jwt-decode";
import { GetKyc } from "./GetKyc";
import Loader from "../Loader";
import { Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { CONSTANTS } from "../../utils/global";
import "./header.scss";
import "bootstrap/dist/js/bootstrap.min.js";

const Header = () => {
  const navRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showNavbar = () => {
    navRef.current.classList.toggle(`${styles.responsive_nav}`);
  };

  const toggle = () => dispatch({ type: "menuTrigger", isOpen: !isOpen });

  const isOpen = useSelector((state) => state.isOpen);

  const userRole = useSelector((state) => state.userRole);

  console.log(userRole);

  const Login = () => {
    const handleSignIn = () => {
      navigate("/login");
    };
    const handleSignUp = () => {
      navigate("/register");
    };
    return (
      <div className="text-center">
        <LoginButton onClick={handleSignIn}>LOGIN</LoginButton>
        <SignUpButton onClick={handleSignUp}>SIGN UP</SignUpButton>
      </div>
    );
  };

  const ProfilePopUp = () => {
    const handleLogout = () => {
      // clear token from localStorage
      Cookies.remove("_gid");
      localStorage.removeItem("token");
      localStorage.removeItem("userRole");

      // localStorage.setItem("username");
      localStorage.removeItem("kyc");
      dispatch({ type: "SET_LOGOUT_STATE", isLoggedOut: true });
      dispatch({ type: "userInfoRole", userRole: null });
      navigate("/login");
    };

    return (
      <ProfilePopWrapper>
        <Container
          fluid
          style={{
            fontFamily: "Bebas Neue",
          }}
        >
          <Row
            className="p-4 mx-3"
            style={{ backgroundColor: "#252525", borderRadius: 3 }}
          >
            <Col className="d-flex align-items-center">
              <img
                style={{
                  height: 50,
                  border: "2pxf solid #00a76a",
                  borderRadius: 100,
                }}
                src={ProfileIcon}
                alt="profile"
              />
              <span
                className="pt-1 px-3"
                style={{
                  fontFamily: "Bebas Neue",
                  fontSize: 30,
                }}
              >
                {localStorage.getItem("username")}
              </span>
            </Col>
          </Row>
          <Row className="my-4">
            <PopUpButton className="link">View Profile</PopUpButton>
            <PopUpButton className="link">Account Settings</PopUpButton>
            <PopUpButton className="link">Help & Support</PopUpButton>
            <PopUpButton className="link">Send Feedback</PopUpButton>
            <PopUpButton onClick={handleLogout} className="link">
              Log out
            </PopUpButton>
          </Row>
        </Container>
      </ProfilePopWrapper>
    );
  };

  const path = window.location.pathname;
  const lastSegment = path.split("/").pop();

  const WalletBalance = () => {
    const [open, setOpen] = useState(false);
    const [balance, setBalance] = useState(0);
    const [walletAddress, setWalletAddress] = useState("");
    const [loading, setLoading] = useState(true);

    const getBalance = async () => {
      setLoading(true);
      let balance = await axiosInstance.get("/balance");
      console.log(balance.data);
      if (balance.data.status === "success") {
        setBalance(balance.data.balance);
        setWalletAddress(balance.data.address);
      }
      setLoading(false);
    };
    useEffect(() => {
      getBalance();
    }, []);
    const handleOpenChange = (newOpen) => {
      setOpen(newOpen);
    };
    return (
      <div className="local-bootstrap">
        <Popover
          content={
            <>
              {!loading ? (
                <div
                  className="local-bootstrap"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="text-center">
                    <h2>{Math.floor(balance * 1000) / 1000 + " MATIC"}</h2>
                  </div>
                  <QRCode
                    errorLevel="H"
                    icon={require("../../assets/wefractionlogosmall.png")}
                    value={walletAddress}
                  />
                  <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
                    <Popover placement="top" content="Copied!" trigger="click">
                      <button
                        className="btn text-center"
                        onClick={() => {
                          navigator.clipboard.writeText(walletAddress);
                        }}
                      >
                        <img src={require("../../assets/ClipboardIcon.png")} />{" "}
                        {walletAddress.substring(0, 5) +
                          "..." +
                          walletAddress.substring(walletAddress.length - 3)}
                      </button>
                    </Popover>
                  </ConfigProvider>
                </div>
              ) : (
                <Loader />
              )}
            </>
          }
          trigger="click"
          open={open}
          onOpenChange={handleOpenChange}
        >
          <img
            className="btn btn-outline-success p-2 me-4"
            style={{ height: 40 }}
            src={require("../../assets/walleticon.png")}
          />
        </Popover>
      </div>
    );
  };

  const GetWalletBalance = () => {
    const token = localStorage.getItem("token");
    if (token && userRole !== "admin") {
      return <WalletBalance />;
    } else {
      return null;
    }
  };

  const KYCFlag = () => {
    const token = localStorage.getItem("token");

    const location = useLocation();
    if (
      location.pathname.endsWith("/login") ||
      location.pathname.endsWith("/register") ||
      location.pathname.endsWith("/brokerRegister")
    ) {
      return null;
    }
    if (token) {
      let jwt = jwtDecode(token);
      if (jwt.UserInfo.roles === "investor") {
        return <GetKyc />;
      }
      return null;
    }
  };

  const UserButton = () => {
    if (lastSegment === "login" || lastSegment === "register") {
      return null;
    } else if (userRole === null) {
      return <Login />;
    } else {
      return <GetUsername />;
    }
  };

  const GetUsername = () => {
    const [ishow, setIsShow] = useState(false);

    const handleShowLogout = () => {
      setIsShow(!ishow);
    };

    return (
      <div
        className={classnames(
          ishow ? styles.profile_userOpen : styles.profile_user
        )}
      >
        <div onClick={handleShowLogout}>
          <img
            src={ProfileIcon}
            alt="profile-user"
            className={styles.profile_icon}
          />
          {/* <div>{username ? <div>{username}</div> : null}</div> */}

          {ishow ? (
            <img src={UpIcon} className={styles.arrowIcon} />
          ) : (
            <img src={DownIcon} className={styles.arrowIcon} />
          )}
        </div>
        {ishow && (
          <>
            <ProfilePopUp />
          </>
        )}
      </div>
    );
  };

  const Dashboard = () => {
    if (userRole === "admin") {
      return <Link to="/admin">Dashboard</Link>;
    } else if (userRole === "broker") {
      return <Link to="/submit-property">Dashboard</Link>;
    } else if (userRole === "investor") {
      return <Link to="/myassets">Dashboard</Link>;
    } else return null;
  };

  return (
    <>
      <Container>
        <Row className="my-5"></Row>
        <Row className="my-4"></Row>
      </Container>
      <ShadowBox></ShadowBox>

      <NavBarWrapper fluid>
        <nav class="navbar navbar-expand-lg navbar-dark bg-transparent h-100">
          <div class="container-fluid p-0">
            <LogoWrapper
              isOpen={isOpen}
              onClick={() => {
                toggle();
              }}
            >
              {isOpen ? (
                <img
                  style={{ height: 70, objectFit: "contain", zIndex: 4 }}
                  src={require("../../assets/fullLogoWhite.png")}
                />
              ) : (
                <img
                  style={{ height: 70, objectFit: "contain", zIndex: 4 }}
                  src={require("../../assets/smallLogoWhite.png")}
                />
              )}
            </LogoWrapper>
            <div></div>

            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div
              class="collapse navbar-collapse"
              style={{
                backgroundColor: "#212121",
              }}
              id="navbarText"
            >
              <ul
                class="navbar-nav mx-auto mb-2 mb-lg-0"
                style={{
                  fontFamily: "Bebas Neue",
                  fontSize: "1.2rem",
                  fontWeight: 100,
                }}
              >
                <li class="nav-item text-center text-lg-start">
                  <Link class="nav-link active" aria-current="page" to={"/"}>
                    HOME
                  </Link>
                </li>
                <li class="nav-item text-center text-lg-start">
                  <a class="nav-link active" target="_blank" href="https://wefraction.com/about-us/">
                    ABOUT US
                  </a>
                </li>
                <li class="nav-item text-center text-lg-start">
                  <Link class="nav-link active" aria-current="page" to={"/"}>
                    PARTNERS
                  </Link>
                </li>
                <li class="nav-item text-center text-lg-start">
                  <a class="nav-link active" href="https://wefraction.helpscoutdocs.com/" target="_blank">
                    RESOURCES
                  </a>
                </li>
                <li class="nav-item text-center text-lg-start">
                  <Link class="nav-link active" aria-current="page" to={"/"}>
                    CONTACT US
                  </Link>
                </li>
              </ul>
              <UserWrapper>
                <KYCFlag />
                <UserButton />
              </UserWrapper>
            </div>
          </div>
        </nav>

        {/* <GetWalletBalance /> */}
        {/* <UserButton /> */}
        {/* <KYCFlag /> */}
      </NavBarWrapper>
    </>
  );
};

export default Header;

const NavBarWrapper = styled(Container)`
  justify-content: space-between;
  background-color: ${CONSTANTS.black};
  height: 130px;
  position: fixed;
  padding: 0;
  z-index: 2;
  top: 0;
`;
const ShadowBox = styled.div`
  display: flex;
  width: 100%;
  height: 130px;
  position: fixed;
  top: 0;
  z-index: 1;
  box-shadow: 0 0 56px 0px #01573863;
`;
const LogoWrapper = styled.div`
  height: 100%;
  width: ${(props) => (props.isOpen ? "300px" : "100px")};
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 30px;
  @media (min-width: 992px) {
    position: absolute;
  }
  cursor: pointer;
`;
const UserWrapper = styled.div`
  @media (max-width: 992px) {
    position: block !important;
    padding: 30px;
  }
  @media (min-width: 993px) {
    position: absolute;
    right: 0;
  }
  padding-right: 80px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
`;

const ProfilePopWrapper = styled.div`
  color: white;
  position: absolute;
  top: 90px;
  padding-top: 30px;
  padding-bottom: 30px;
  right: -20px;
  border: 2px solid ${CONSTANTS.green};
  border-radius: 10px;
  width: 380px;
  background-color: #212121;
  @media (max-width: 992px) {
    top: 100%;
  }
`;

const PopUpButton = styled.div`
  color: white;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 25px;
  font-size: 1.3rem;
  cursor: pointer;
  &:hover {
    background-color: #252525;
  }
`;

const SignUpButton = styled.button`
  color: white;
  padding-top: 12px;
  background-color: ${CONSTANTS.green};
  padding-bottom: 12px;
  padding-left: 40px;
  padding-right: 40px;
  font-family: "Bebas Neue";
  font-size: 1.3rem;
  border: 0;
  border: 2px solid transparent;
  cursor: pointer;
  &:hover {
    background-color: #252525;
    border: 2px solid white;
  }
`;
const LoginButton = styled.button`
  color: ${CONSTANTS.green};
  padding-top: 12px;
  background-color: transparent;
  padding-bottom: 12px;
  padding-left: 40px;
  padding-right: 40px;
  font-family: "Bebas Neue";
  font-size: 1.3rem;
  border: 0;
  margin-right: 20px;
  border: 2px solid ${CONSTANTS.green};
  cursor: pointer;
  &:hover {
    color: white;
    background-color: #252525;
    border: 2px solid white;
  }
`;
