import { Carousel } from "react-bootstrap";
import React, { useState } from "react";

export default function ItemCarousel({ images = {} }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <Carousel activeIndex={index} onSelect={handleSelect} indicators={false}>
      {/* {images.map((x, index) => ( */}
        <Carousel.Item key={1}>
          <img
            style={{ height: 200, objectFit: "contain" }}
            className="d-block w-100"
            src={require("../../assets/1bed.jpeg")}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item key={0}>
          <img
            style={{ height: 200, objectFit: "contain" }}
            className="d-block w-100"
            src={require("../../assets/2bed.jpeg")}
            alt="First slide"
          />
        </Carousel.Item>
      {/* ))} */}
    </Carousel>
  );
}
