import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router";

export default function NoLoggedIn() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userRole = useSelector((state) => state.userRole);
  useEffect(() => {
    if (userRole && !token) {
      navigate("/");
    }
  }, []);

  return <Outlet />;
}
