import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
const { Dragger } = Upload;

const DropZone = ({ name, setState }) => {
  const handleImageUpload = (info) => {
    const { status, originFileObj } = info.file;

    if (status === "done") {
      console.log(originFileObj);
      // setState((prevItem) => ({ ...prevItem, image: originFileObj }));
      // push new image to newItem.images array
      setState((prevItem) => [...prevItem, originFileObj]);
    } else if (status === "error") {
      alert(`${info.file.name} file upload failed.`);
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <Dragger
      name={name}
      style={{
        border: "2px solid #00a76a",
        borderRadius: 0,
      }}
      multiple={true}
      onChange={handleImageUpload}
      customRequest={dummyRequest}
      listType="picture"
      onRemove={(e) => {
        setState((prevItem) => [
          ...prevItem.filter((item) => item.uid !== e.uid),
        ]);
      }}
    >
      <div className="antd-dropzone">
        <span className="antd-dropzone-text">DRAG AND DROP FILES HERE</span>
      </div>
    </Dragger>
  );
};
export default DropZone;
