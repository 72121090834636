import React, { useState } from "react";
import styles from "./Properties.module.css";
import classnames from "classnames";
import UploadIcon from "../../assets/upload-icon.png";
import Select from "react-select";
import "react-toggle/style.css";
import { useSelector } from "react-redux";
import classNames from "classnames";
import TrashIcon from "../../assets/ph_trash.png";
import axios from "axios";
import { submitProperty } from "../../utils/api";
import LoaderSecondary from "../LoaderSecondary";
import { Button, Modal } from "antd";
import BASE_URL from "../../utils/config";
import FormGroup from "../FormGroup";
import ChoiceSelector from "./ChoiceSelector";
import DropZone from "./DropZone";

const Properties = () => {
  const [isOn, setIsOn] = useState(false);
  const [isRented, setIsRented] = useState(false);
  const [isLoans, setIsLoans] = useState(false);
  const [progress, setProgress] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState("");
  const [selectedOption2, setSelectedOption2] = useState("");
  const [selectedOption3, setSelectedOption3] = useState("");
  const [selectedOption4, setSelectedOption4] = useState([]);
  const [selectedOption5, setSelectedOption5] = useState([]);
  const [completeModal, setCompleteModal] = useState(false);

  const isOpen = useSelector((state) => state.isOpen);
  // const [selectedFile1, setSelected1File] = useState("");
  const [files1, setFiles1] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [files3, setFiles3] = useState([]);

  var Css = styles.menuOpen;
  var Css1 = styles.menuOpen1;
  if (isOpen == false) {
    Css = styles.menuClose;
    Css1 = styles.menuClose1;
  }

  // options1 = asset_type
  const options1 = [
    { value: "Gym", label: "Gym" },
    { value: "Apartment", label: "Apartment" },
    { value: "Villa", label: "Villa" },
    { value: "Townhouse", label: "Townhouse" },
    { value: "Commercial (Office)", label: "Commercial (Office)" },
    { value: "Commercial (Shop)", label: "Commercial (Shop)" },
  ];

  // options2 = number_of_beds
  const options2 = [
    { value: "studio", label: "Studio" },
    { value: "2 Bed", label: "2 Bed" },
    { value: "3 Bed", label: "3 Bed" },
    { value: "4 Bed", label: "4 Bed" },
    { value: "5 Bed", label: "5 Bed" },
    { value: "6 Bed", label: "6 Bed" },
  ];

  // options3 = number_of_baths
  const options3 = [
    { value: "1 Bath", label: "1 Bath" },
    { value: "2 Bath", label: "2 Bath" },
    { value: "3 Bath", label: "3 Bath" },
    { value: "4 Bath", label: "4 Bath" },
    { value: "5 Bath", label: "5 Bath" },
    { value: "6 Bath", label: "6 Bath" },
  ];

  const [isOtherSelected, setIsOtherSelected] = useState(false);

  // options4 = amenities
  const options4 = [
    { value: "Gym", label: "Gym" },
    { value: "Concierge", label: "Concierge" },
    { value: "Building / Community Pool", label: "Building / Community Pool" },
    { value: "Sauna", label: "Sauna" },
    { value: "Jacuzzi", label: "Jacuzzi" },
    { value: "Private Balcony", label: "Private Balcony" },
    { value: "Private Pool", label: "Private Pool" },
    { value: "Sea View", label: "Sea View" },
    { value: "Canal View", label: "Canal View" },
    { value: "Downtown View", label: "Downtown View" },
    { value: "Burj Khalifa View", label: "Burj Khalifa View" },
    { value: "Others", label: "Others" },
  ];

  // options5 = off_plan
  const options5 = [
    { value: 0, label: "No" },
    { value: 1, label: "Yes" },
  ];

  const customStyles = {
    multiValue: (styles) => ({
      ...styles,
      color: "White", // set the font color to white for better contrast
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#151515",
      // outline: "1px solid #00a76a",
      width: "auto",
      border: "none",
      padding: "0.3vw",
      fontSize: "1.1rem",
      fontWeight: "700",
      color: "White",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
      // specify a fallback color here for those values not accounted for in the styleMap
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#fff",
      fontSize: "1.1rem",
      fontWeight: "700",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#151515",
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#00a76a" : "#151515",
      padding: "1rem",
      color: "White",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#151515",
        border: "1px solid #00a76a",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#fff",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ":hover": {
        backgroundColor: data.color,
        color: "white",
      },
    }),
  };

  const mediaQueryStyles = {
    "@media (max-width: 700px)": {
      control: (provided) => ({
        ...provided,
        width: "64.5vw",
        fontSize: "1rem",
        // your custom control styles for small screens
      }),
      placeholder: (provided) => ({
        ...provided,
        fontSize: "1rem",
      }),
      // other custom styles for the select component on small screens
    },
  };

  const stylesSelect = { ...customStyles, ...mediaQueryStyles };

  // Handle Toggle
  const HandleToggleClick = () => {
    setIsOn(!isOn);
  };

  const HandleRentedToggle = () => {
    setIsRented(!isRented);
  };

  const HandleLoansToggle = () => {
    setIsLoans(!isLoans);
  };

  // Handle Options
  const handleOption1Change = (selectedOption) => {
    setSelectedOption1(selectedOption);
  };

  const handleOption2Change = (selectedOption) => {
    setSelectedOption2(selectedOption);
  };
  const handleOption3Change = (selectedOption) => {
    setSelectedOption3(selectedOption);
  };
  const handleOption4Change = (selectedOption) => {
    setSelectedOption4(selectedOption);
  };

  const handleOption5Change = (selectedOption) => {
    setSelectedOption5(selectedOption);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const [formData, setFormData] = useState({
    size_of_asset_sqft: "",
    extra_amenities: "",

    building_name: "",
    unit_number: "",
    floor: "",
    area: "",
    city_name: "",

    sale_price: "",
    yearly_rent_value: "",
    service_charges_sqft: "",
    comment: "",
  });

  const token = localStorage.getItem("token");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(true);

    const data = new FormData();
    data.append("asset_type", selectedOption1.value);
    data.append("size_of_asset_sqft", formData.size_of_asset_sqft);
    data.append("off_plan", selectedOption5.value);
    data.append("number_of_beds", selectedOption2.value);
    data.append("number_of_baths", selectedOption3.value);
    if (Array.isArray(selectedOption4)) {
      data.append(
        "amenities",
        JSON.stringify(selectedOption4.map((option) => option.value))
      );
    } else {
      data.append("amenities", JSON.stringify([]));
    }

    data.append("extra_amenities", formData.extra_amenities);
    data.append("building_name", formData.building_name);
    data.append("unit_number", formData.unit_number);
    data.append("floor", formData.floor);
    data.append("area", formData.area);
    data.append("city_name", formData.city_name);
    data.append("sale_price", formData.sale_price);
    data.append("rented", isRented);
    data.append("yearly_rent_value", formData.yearly_rent_value);
    data.append("service_charges_sqft", formData.service_charges_sqft);
    data.append("loans_payments_mortgages", isLoans);

    files1.forEach((file) => {
      data.append("title_deed", file);
    });

    files2.forEach((file) => {
      data.append("developer_brochure", file);
    });

    files3.forEach((file) => {
      data.append("asset_layout", file);
    });

    data.append("comment", formData.comment);
    // data.append(
    //   "developer_marketing_material",
    //   formData.developer_marketing_material
    // );

    console.log(Object.fromEntries(data));

    // for (var pair of data.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: BASE_URL + "/submit_property",
      headers: {
        // Authorization: `Bearer ${token}`,
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySW5mbyI6eyJpZCI6MSwiZW1haWwiOiJnaGF6aUBnbWFpbC5jb20iLCJyb2xlcyI6ImJyb2tlciJ9LCJpYXQiOjE2ODczNDc1MTIsImV4cCI6MTY5NTEyMzUxMn0.ruX5hCJ6Kt9BEDdzqE7vyAGW2uMA38pkOS9H6y760wU`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setProgress(false);
        setCompleteModal(true);
      })

      .catch((error) => {
        setProgress(false);
        console.log(error);
      });
  };

  return (
    <div className={classNames(styles.container, Css)}>
      <Modal
        centered
        title="Property Submitted!"
        open={completeModal}
        footer={[
          <Button
            type="primary"
            onClick={() => {
              setCompleteModal(false);
              window.location.reload();
              window.scrollTo({ top: 0 });
            }}
          >
            Great
          </Button>,
        ]}
      >
        <p>Thank you for submitting your property. </p>
        <p>
          Our team has received it and will review it shortly. We will get back
          to you as soon as possible with our feedback.
        </p>
      </Modal>
      <div className={styles.t1}>Property Info</div>
      <div className={styles.wrapper}>
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className={styles.form}
        >
          <h2 className={styles.formSectionTitle}>PROPERTY INFORMATION</h2>
          <div className={styles.formSection}>
            <div className={styles.label1}>
              <Select
                options={options1}
                onChange={handleOption1Change}
                value={selectedOption1}
                // styles={stylesSelect}
                unstyled
                className={styles.reactSelect}
                classNamePrefix="reactSelect"
                placeholder="Asset Type"
              />
            </div>
            <FormGroup
              label="Size of the Asset"
              name="size_of_asset_sqft"
              required
              state={formData.size_of_asset_sqft}
              handleChange={handleInputChange}
            />

            <div className={styles.label1}>
              <Select
                options={options5}
                onChange={handleOption5Change}
                value={selectedOption5}
                // styles={stylesSelect}
                unstyled
                className={styles.reactSelect}
                classNamePrefix="reactSelect"
                placeholder="Off Plan"
              />
            </div>

            <div className={styles.label1}>
              <Select
                options={options2}
                onChange={handleOption2Change}
                value={selectedOption2}
                // styles={stylesSelect}
                unstyled
                className={styles.reactSelect}
                classNamePrefix="reactSelect"
                placeholder="Number of Beds"
              />
            </div>

            <div className={styles.label1}>
              <Select
                options={options3}
                onChange={handleOption3Change}
                value={selectedOption3}
                // styles={stylesSelect}
                unstyled
                className={styles.reactSelect}
                classNamePrefix="reactSelect"
                placeholder="Number of Baths"
              />
            </div>

            <div htmlFor="amenities" className={styles.label1}>
              <Select
                options={options4}
                onChange={handleOption4Change}
                // defaultValue={[selectedOption1[0], selectedOption1[1]]}
                unstyled
                isMulti
                value={selectedOption4}
                classNamePrefix="reactSelect"
                placeholder="Amenities"
              />
            </div>
            <FormGroup
              label="Extra Amenities"
              name="extra_amenities"
              state={formData.extra_amenities}
              handleChange={handleInputChange}
            />
          </div>

          <h2 className={styles.formSectionTitle}>ADDRESS</h2>
          <div className={styles.formSection}>
            <FormGroup
              label="Building Name"
              name="building_name"
              state={formData.building_name}
              handleChange={handleInputChange}
              required
            />
            <FormGroup
              label="Unit Number"
              name="unit_number"
              required
              state={formData.unit_number}
              handleChange={handleInputChange}
            />

            <FormGroup
              label="Floor"
              name="floor"
              required
              state={formData.floor}
              handleChange={handleInputChange}
            />
            <FormGroup
              label="Area"
              name="area"
              required
              state={formData.area}
              handleChange={handleInputChange}
            />

            <FormGroup
              label="City"
              name="city_name"
              required
              state={formData.city_name}
              handleChange={handleInputChange}
            />
          </div>

          <h2 className={styles.formSectionTitle}>PRICE</h2>
          <div className={styles.formSection}>
            <FormGroup
              label="Recommended Sale Price"
              name="sale_price"
              required
              state={formData.sale_price}
              handleChange={handleInputChange}
            />

            {/* todo rented */}
            <ChoiceSelector
              title="Rented"
              state={isRented}
              handleState={HandleRentedToggle}
            />

            {isRented && (
              <>
                <FormGroup
                  label="Yearly Rent Value"
                  name="yearly_rent_value"
                  required
                  state={formData.yearly_rent_value}
                  handleChange={handleInputChange}
                />
              </>
            )}
            <FormGroup
              label="Service Charges (/SQFT)"
              name="service_charges_sqft"
              required
              state={formData.service_charges_sqft}
              handleChange={handleInputChange}
            />

            <ChoiceSelector
              title="ARE THERE ANY LOANS / PAYMENTS / MORTGAGES ATTACHED TO THE PROPERTY?"
              state={isLoans}
              handleState={HandleLoansToggle}
            />
          </div>

          <h2 className={styles.formSectionTitle}>DOCUMENTS NEEDED</h2>
          <div className={styles.formSection}>
            <div>
              <div className={styles.label2}>
                <div className={styles.t3}>Title Deed</div>
              </div>
              <DropZone setState={setFiles1} name="title_deed" />
            </div>
          </div>

          <div className={styles.formSection}>
            <div>
              <div className={styles.label2}>
                <div className={styles.t3}>DEVELOPER MARKETING MATERIAL</div>
              </div>
              <DropZone setState={setFiles2} name="developer_brochure" />
            </div>
          </div>

          <div className={styles.formSection}>
            <div>
              <div className={styles.label2}>
                <div className={styles.t3}>ASSET LAYOUT (PLANS)</div>
              </div>
              <DropZone setState={setFiles3} name="asset_layout" />
            </div>
          </div>

          <h2 className={styles.formSectionTitle}>
            Anything else you would like us to know that can be helpful in
            promoting the asset ?
          </h2>
          <div className={styles.formSection}>
            <FormGroup
              label="Comments"
              name="comment"
              state={formData.comment}
              handleChange={handleInputChange}
              required
            />
          </div>

          <div className={styles.formSection}>
            <button
              type="submit"
              // disabled={!isChecked}
              // onClick={handleSubmit}
              className={styles.PropertySubmitBtn}
            >
              Submit Property {progress && <LoaderSecondary />}
            </button>
          </div>

          {/* <React.Fragment>
            <label htmlFor="building" className={styles.label1}>
              <div className={styles.t3}>
                Building Name <span className={styles.span1}>*</span>
              </div>

              <input
                type="text"
                id="building"
                name="building_name"
                placeholder="Building Name"
                required
                value={formData.building_name}
                onChange={handleInputChange}
              />
            </label>
            <label htmlFor="city" className={styles.label1}>
              <div className={styles.t3}>
                City <span className={styles.span1}>*</span>
              </div>

              <input
                type="text"
                id="city"
                name="city_name"
                placeholder="City"
                required
                value={formData.city_name}
                onChange={handleInputChange}
              />
            </label>

            <label htmlFor="area" className={styles.label1}>
              <div className={styles.t3}>
                Area <span className={styles.span1}>*</span>
              </div>

              <input
                type="text"
                id="area"
                name="area"
                placeholder="Area"
                required
                value={formData.area}
                onChange={handleInputChange}
              />
            </label>

            <label htmlFor="floor" className={styles.label1}>
              <div className={styles.t3}>Floor</div>

              <input
                type="text"
                id="floor"
                name="floor"
                placeholder="2nd"
                required
                value={formData.floor}
                onChange={handleInputChange}
              />
            </label>

            <label htmlFor="unitNumber" className={styles.label1}>
              <div className={styles.t3}>Unit Number</div>

              <input
                type="text"
                id="unitNumber"
                name="unit_number"
                placeholder="196A7T8E"
                required
                value={formData.unit_number}
                onChange={handleInputChange}
              />
            </label>

            <label htmlFor="assetType" className={styles.label1}>
              <div className={styles.t3}>
                Asset Type <span className={styles.span1}>*</span>
              </div>
              <Select
                options={options1}
                onChange={handleOption1Change}
                value={selectedOption1}
                styles={stylesSelect}
                placeholder="Select an Option"
              />
            </label>

            <label htmlFor="assetSize" className={styles.label1}>
              <div className={styles.t3}>Size of the assets</div>

              <input
                type="text"
                id="assetSize"
                name="size_of_asset_sqft"
                placeholder="1000.00 Sqft"
                required
                value={formData.size_of_asset_sqft}
                onChange={handleInputChange}
              />
            </label>
            <label htmlFor="0ff_plan" className={styles.label1}>
              <div className={styles.t3}>Off Plan</div>

              <button
                type="button"
                onClick={HandleToggleClick}
                className={classnames(
                  styles.toggleButton,
                  isOn ? styles.on : ""
                )}
              ></button>
            </label>

            <label className={styles.label1}>
              <div className={styles.t3}>Number Of Beds</div>
              <Select
                options={options2}
                onChange={handleOption2Change}
                value={selectedOption2}
                styles={stylesSelect}
                placeholder="Select an Option"
              />
            </label>
            <label htmlFor="assetType" className={styles.label1}>
              <div className={styles.t3}>Number Of Bath</div>

              <Select
                options={options3}
                onChange={handleOption3Change}
                value={selectedOption3}
                styles={stylesSelect}
                placeholder="Select an Option"
              />
            </label>

            <label htmlFor="amenities" className={styles.label1}>
              <div className={styles.t3}>Amenities Available</div>

              <Select
                options={options4}
                onChange={handleOption4Change}
                // defaultValue={[selectedOption1[0], selectedOption1[1]]}
                isMulti
                value={selectedOption4}
                styles={stylesSelect}
                placeholder="Select an Option"
              />
            </label>

            <label htmlFor="extraA" className={styles.label1}>
              <div className={styles.t3}>Extra Amenities</div>

              <input
                type="text"
                id="extraA"
                name="extra_amenities"
                placeholder="196E"
                disabled={
                  JSON.stringify(
                    selectedOption4.map((option) => option.value)
                  ).indexOf("Others") === -1
                }
                // required
                value={formData.extra_amenities}
                onChange={handleInputChange}
              />
            </label>

            <label htmlFor="rented2" className={styles.label1}>
              <div className={styles.t3}>Rented</div>

              <button
                type="button"
                onClick={HandleRentedToggle}
                className={classnames(
                  styles.toggleButton,
                  isRented ? styles.on : ""
                )}
              />
            </label>

            <label htmlFor="service_charges_sqft" className={styles.label1}>
              <div className={styles.t3}>
                Services Charges <sub>(Sqft)</sub>
                <span className={styles.span1}>*</span>
              </div>

              <input
                type="text"
                id="service_charges_sqft"
                name="service_charges_sqft"
                placeholder="1,000.00"
                // required
                value={formData.service_charges_sqft}
                onChange={handleInputChange}
              />
            </label>            

            <label htmlFor="loans" className={styles.label1}>
              <div className={styles.t3}>
                Are there any Loans / Payments / Mortgages attached to the
                property ?
              </div>

              <button
                type="button"
                onClick={HandleLoansToggle}
                className={classnames(
                  styles.toggleButton,
                  isLoans ? styles.on : ""
                )}
              ></button>
            </label>

            <label
              htmlFor="developer_marketing_material"
              className={styles.label1}
            >
              <div className={styles.t3}>Total Outstadnig Amount</div>

              <input
                type="text"
                id="developer_marketing_material"
                name="developer_marketing_material"
                placeholder="1000.00"
                disabled={!isLoans}
                // required
                value={formData.developer_marketing_material}
                onChange={handleInputChange}
              />
            </label>

            <div className={styles.label2}>
              <div className={styles.t3}>
                Developer Brochure
                <span className={styles.span1}>*</span>
              </div>
              <div>
                <label className={styles.labelFile}>
                  Upload
                  <img
                    src={UploadIcon}
                    alt="upload-icon"
                    className={styles.uploadIcon}
                  />
                  <input
                    type="file"
                    multiple
                    id="developer_marketing"
                    name="developer_brochure"
                    // required
                    onChange={(e) => handleFileChange(e, setFiles2)}
                  />
                </label>
                {files2.length > 0 && (
                  <div>
                    <ul className={styles.uploadedList}>
                      {files2.map((file) => (
                        <li key={file.name} className={styles.li}>
                          <div> {file.name}</div>
                          <img
                            src={TrashIcon}
                            onClick={() =>
                              handleFileRemove(file.name, setFiles2)
                            }
                            className={styles.trash}
                            alt="trash"
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.label2}>
              <div className={styles.t3}>
                Asset Layout
                <span className={styles.span1}>*</span>
              </div>
              <div>
                <label htmlFor="asset_layout" className={styles.labelFile}>
                  Upload
                  <img
                    src={UploadIcon}
                    alt="upload-icon"
                    className={styles.uploadIcon}
                  />
                  <input
                    type="file"
                    multiple
                    id="asset_layout"
                    name="asset_layout"
                    // required
                    onChange={(e) => handleFileChange(e, setFiles3)}
                  />
                </label>
                {files3.length > 0 && (
                  <div>
                    <ul className={styles.uploadedList}>
                      {files3.map((file) => (
                        <li key={file.name} className={styles.li}>
                          <div> {file.name}</div>
                          <img
                            src={TrashIcon}
                            onClick={() =>
                              handleFileRemove(file.name, setFiles3)
                            }
                            className={styles.trash}
                            alt="trash"
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <label htmlFor="comment" className={styles.labelArea}>
              <div className={styles.t4}>
                Anything else you would like us to know that can be helpful in
                promoting the asset ?
              </div>

              <textarea
                id="comment"
                name="comment"
                value={formData.comment}
                onChange={handleInputChange}
              />
            </label>

            <button
              type="submit"
              // disabled={!isChecked}
              // onClick={handleSubmit}
              className={styles.PropertySubmitBtn}
            >
              Submit Property {progress && <LoaderSecondary />}
            </button>
          </React.Fragment> */}
        </form>
      </div>
    </div>
  );
};

export default Properties;
