const persistedUserRole = localStorage.getItem("userRole");

function reducer(
  state = {
    reload: false,
    isOpen: false,
    isFocused: false,
    userRole: persistedUserRole ? persistedUserRole : null,
    isLoggedOut: false,
    kyc: null,
  },
  action
) {
  switch (action.type) {
    case "SET_KYC":
      return {
        ...state,
        kyc: action.kyc,
      };
    case "menuTrigger":
      return {
        ...state,
        isOpen: action.isOpen,
      };
    case "inputFocused":
      return {
        ...state,
        isFocused: action.isFocused,
      };
    case "userInfoRole":
      return {
        ...state,
        userRole: action.userRole,
      };
    case "SET_LOGOUT_STATE":
      return {
        ...state,
        isLoggedOut: action.isLoggedOut,
      };
    default:
      return state;
  }
}
export default reducer;
