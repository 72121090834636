import React, { useState, useEffect } from "react";
import { CheckKyc } from "../../utils/api";
import Unverified from "../../assets/unverified.svg";
import Verified from "../../assets/verified.png";
import styles from "./GetKyc.module.css";
import BASE_URL from "../../utils/config";
import axiosInstance from "../../Axios/AxiosInstance";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const GetKyc = () => {
  const [KYC, setKyc] = useState(localStorage.getItem("kyc") || "");

  const getKycData = async () => {
    try {
      let data = await axiosInstance.get("/kyc/check");
      console.log(data.data);

      if (data.data.status === "success") {
        setKyc(data.data.check);
        localStorage.setItem("kyc", data.data.check);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getKycData();
  }, []);

  return (
    <div>
      {KYC === "Unverified" && localStorage.getItem("token") ? (
        <Link to={"/kyc"} className={styles.kyc_unverified}>
          <img src={Unverified} alt="icon" />
          <div>{KYC ? <div> {KYC}</div> : null}</div>
        </Link>
      ) : (
        KYC === "Verified" &&
        localStorage.getItem("token") && (
          <div className={styles.kyc_verified}>
            <img src={Verified} alt="icon" />
            <div>{KYC ? <div> {KYC}</div> : null}</div>
          </div>
        )
      )}
    </div>
  );
};
