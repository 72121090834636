import React, { useState, useEffect } from "react";
import styles from "./List.module.css";
import { SearchBar } from "./SearchBar";
import { FilterList } from "./FilterList";
import { CardList } from "./CardList";
import { MostViewed } from "./MostViewed";
import { useSelector } from "react-redux";
import axios from "axios";
import classNames from "classnames";
import BASE_URL from "../../utils/config";
import {
  Alert,
  Button,
  ConfigProvider,
  Descriptions,
  Dropdown,
  Input,
  InputNumber,
  Modal,
  Popover,
  Progress,
  Space,
  Steps,
  Upload,
  theme,
} from "antd";
import { Container, Row } from "react-bootstrap";
import axiosInstance from "../../Axios/AxiosInstance";

const NAME = "WEFRACTION";
const IBAN = "AE070331234567890123456";

const List = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isOpen = useSelector((state) => state.isOpen);
  const userRole = useSelector((state) => state.userRole);

  const [walletBalance, setWalletBalance] = useState(4000);
  const [openModal, setOpenModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("bank_transfer");
  const [currentStep, setCurrentStep] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(6000);
  const [numberOfTokensToBuy, setNumberOfTokensToBuy] = useState(1);
  const [ticketsLeft, setTicketsLeft] = useState(null);
  let [propertyData, setPropertyData] = useState(null);
  const [isPaymentButtonDisabled, setIsPaymentButtonDisabled] = useState(true);
  const [doingPayment, setDoingPayment] = useState(false);
  const [openModalCongrats, setModalCongrats] = useState(false);
  const [paymentProof, setPaymentProof] = useState([]);

  useEffect(() => {
    if (paymentMethod === "crypto") {
      if (tokenPrice <= walletBalance) {
        setIsPaymentButtonDisabled(false);
      } else {
        setIsPaymentButtonDisabled(true);
      }
    } else if (paymentMethod === "bank_transfer") {
      if (paymentProof.length >= 1) {
        setIsPaymentButtonDisabled(false);
      } else {
        setIsPaymentButtonDisabled(true);
      }
    }
  }, [paymentMethod, paymentProof.length, tokenPrice, walletBalance]);

  const handleConfirmPayment = async () => {
    setDoingPayment(true);
    setTicketsLeft(null);
    let data = new FormData();
    data.append("upload_proof", paymentProof[0].originFileObj);
    // data.append("project_id", process.env.NODE_ENV === "development" ? "3" : "2");
    // data.append("accountId", process.env.NODE_ENV === "development" ? "1" : "2");
    data.append("project_id", "2");
    data.append("accountId", "2");
    data.append("payment_type", paymentMethod);
    data.append("number_of_tickets", numberOfTokensToBuy);

    let res = await axiosInstance.post("/invest", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(res);
    if (res.data.message) {
      console.log(res.data.ticket_available);
      setTicketsLeft(res.data.ticket_available);
    } else if (res.data.status === "success") {
      setPaymentProof([]);
      setOpenModal(false);
      setCurrentStep(0);
      setModalCongrats(true);
    }
    console.log(Array.from(data.entries()));
    setDoingPayment(false);
  };

  const handleChangePaymentProof = (info) => {
    let newFileList = [...info.fileList];
    setPaymentProof(newFileList);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const next = () => {
    setCurrentStep(currentStep + 1);
  };
  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const steps = [
    {
      title: "Choose Amount",
      content: (
        <Container>
          <Row>
            <Descriptions bordered className="my-2">
              <Descriptions.Item label="Ticket Price" span={3}>
                {tokenPrice}
              </Descriptions.Item>
              <Descriptions.Item label="Total" span={3}>
                {tokenPrice * numberOfTokensToBuy}
              </Descriptions.Item>
            </Descriptions>
          </Row>
          <Row>
            <p className="me-5" style={{ fontSize: 20 }}></p>
            <InputNumber
              addonBefore={"Number Of Tickets"}
              min={1}
              max={ticketsLeft}
              defaultValue={numberOfTokensToBuy}
              onChange={(value) => {
                setNumberOfTokensToBuy(value);
              }}
            />
            {ticketsLeft ? (
              <Alert
                className="my-2"
                description={ticketsLeft + " Tickets Left"}
                type="info"
                showIcon
              />
            ) : (
              ""
            )}
          </Row>
        </Container>
      ),
    },
    {
      title: "Payment Info",
      content: (
        <div>
          <Descriptions bordered className="my-2">
            <Descriptions.Item label="IBAN" span={3} className="">
              {IBAN}
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(IBAN);
                }}
                className="border-0 bg-transparent shadow-none"
              >
                <img src={require("../../assets/ClipboardIcon.png")} />
              </Button>
            </Descriptions.Item>
            <Descriptions.Item label="NAME" span={3} className="">
              {NAME}
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(NAME);
                }}
                className="border-0 bg-transparent shadow-none"
              >
                <img src={require("../../assets/ClipboardIcon.png")} />
              </Button>
            </Descriptions.Item>
            <Descriptions.Item label="AMOUNT" span={3} className="">
              {tokenPrice * numberOfTokensToBuy} $
            </Descriptions.Item>
            <Descriptions.Item
              label="DLD Registration Fee 4%"
              span={3}
              className=""
            >
              <span style={{ fontWeight: 100 }} className="opensans">
                {tokenPrice * numberOfTokensToBuy * 0.04} $
              </span>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${tokenPrice * numberOfTokensToBuy * 0.04} $`
                  );
                }}
                className="border-0 bg-transparent shadow-none"
              ></Button>
            </Descriptions.Item>
            <Descriptions.Item label="Total" span={3} className="">
              <span style={{ fontWeight: 100 }} className="opensans">
                {tokenPrice * numberOfTokensToBuy * 1.04} $
              </span>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${tokenPrice * numberOfTokensToBuy * 1.04} $`
                  );
                }}
                className="border-0 bg-transparent shadow-none"
              >
                <img src={require("../../assets/ClipboardIcon.png")} />
              </Button>
            </Descriptions.Item>
            <Descriptions.Item label="TRANSFER NOTE" span={3} className="">
              <span style={{ fontWeight: 100 }} className="opensans">
                {"WF-" + 100 + "-" + 1 + "-" + numberOfTokensToBuy}
              </span>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(
                    "WF-" + 100 + "-" + 1 + "-" + numberOfTokensToBuy
                  );
                }}
                className="border-0 bg-transparent shadow-none"
              >
                <img src={require("../../assets/ClipboardIcon.png")} />
              </Button>
            </Descriptions.Item>
          </Descriptions>
          <div className="mt-5 mb-3">
            <span className="me-5" style={{ fontSize: 20 }}>
              UPLOAD PROOF
            </span>
            <Upload
              listType="picture"
              fileList={paymentProof}
              customRequest={dummyRequest}
              onChange={handleChangePaymentProof}
              maxCount={1}
            >
              <Button
                className="border-green"
                style={{
                  borderWidth: 1,
                  backgroundColor: "transparent",
                  height: 40,
                }}
              >
                <span
                  style={{
                    fontSize: 20,
                    fontWeight: 100,
                  }}
                  className="text-green"
                >
                  Upload
                </span>
                <img
                  className="mb-1 ms-3"
                  style={{ height: 20 }}
                  src={require("../../assets/upload-icon.png")}
                />
              </Button>
            </Upload>
          </div>
          <Alert
            message="Note"
            description="Please be advised that your tickets will be held in reserve status until payment is verified."
            type="warning"
            showIcon
          />
          {ticketsLeft ? (
            <Alert
              className="my-2"
              description={ticketsLeft + " Tickets Left"}
              type="info"
              showIcon
            />
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const isAdmin = () => (userRole === "admin" ? true : false);

  const getListData = () => {
    setIsLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: BASE_URL + "/properties",
    };

    axios
      .request(config)
      .then((response) => {
        setData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getListData();
  }, []);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleFilterChange = (filters) => {
    setSelectedFilters(filters);
  };

  if (isAdmin()) {
    Css = styles.menuHidden;
  }

  var Css = styles.menuOpen;
  if (isOpen == false) {
    Css = styles.menuClose;
  }

  const bankStepsStyle = {
    textAlign: "start",
    border: "0",
    marginTop: 16,
    controlItemBgActive: "#00a76a",
  };

  return (
    <div className={classNames(styles.list, Css)}>
      <div className={styles.card_list}>
        <CardList
          searchQuery={searchQuery}
          selectedFilters={selectedFilters}
          data={data}
          isLoading={isLoading}
          setOpenModal={setOpenModal}
        />
        <ConfigProvider
          theme={{
            algorithm: theme.darkAlgorithm,
          }}
        >
          <Modal
            className="paymentModal local-bootstrap"
            title={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span>PAYMENT METHOD</span>
              </div>
            }
            open={openModal}
            footer={[]}
          >
            <span className="me-5 openSans" style={{ fontSize: 16 }}>
              Payment Type
            </span>

            <select
              className="selectPayment mb-4"
              onChange={(e) => {
                setPaymentMethod(e.target.value);
              }}
            >
              <option value={"bank_transfer"}>Bank Transfer</option>
            </select>

            <>
              <Steps current={currentStep} items={items} />
              <div style={bankStepsStyle}>{steps[currentStep].content}</div>
              <div
                style={{
                  marginTop: 24,
                }}
              >
                {currentStep < steps.length - 1 && (
                  <Button type="primary" onClick={() => next()}>
                    Next
                  </Button>
                )}
                {currentStep > 0 && (
                  <Button
                    style={{
                      margin: "0 8px",
                    }}
                    onClick={() => prev()}
                  >
                    Previous
                  </Button>
                )}
                {currentStep === steps.length - 1 && (
                  <Button
                    disabled={isPaymentButtonDisabled || doingPayment}
                    type="primary"
                    onClick={() => {
                      handleConfirmPayment();
                    }}
                    style={{
                      fontWeight: "normal",
                    }}
                  >
                    COMPLETE PAYMENT
                  </Button>
                )}
              </div>
            </>

            <div
              className="mt-4"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <span></span>
              <span style={{ fontSize: 25 }}>
                <button
                  disabled={doingPayment}
                  className="bg-transparent border-0 mx-2"
                  style={{ color: "white" }}
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  CANCEL
                </button>
              </span>
            </div>
          </Modal>
          <Modal
            centered
            title="Payment Submitted"
            open={openModalCongrats}
            footer={
              <Button
                onClick={() => {
                  setModalCongrats(false);
                }}
                type="primary"
              >
                Great!
              </Button>
            }
          >
            <p>
              Our team will proccess the payment and get back to you shortly!
            </p>
          </Modal>
        </ConfigProvider>
      </div>
    </div>
  );
};

export default List;
