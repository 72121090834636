import "./Contacus.scss";
import { Collapse, ConfigProvider, theme } from "antd";
import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
const { Panel } = Collapse;

export default function Contactus() {
  let FAQs = [
    {
      header: "Are you a crypto business?",
      text: "While we use the blockchain as a tool to verify transactions and maintain a public ledger, we are NOT a crypto business. We are a proptech platform.",
    },
    {
      header: "How is the investment structured?",
      text: "All investments happen at the title deed level. This means that our agreement with the Dubai Land Department, WeFraction issues micro title-deeds for each fraction. In essence your fraction is title deed of the exact square footage you own in the asset you invested in.",
    },
    {
      header: "What is a micro title deed?",
      text: "A micro title deed is a document issued by the Dubai Land Department that is the same as a regular title deed in every way except that a regular title deed represents a full asset while a micro deed represents a fraction of an asset. ",
    },
    {
      header: "Whose name will be on the title deed?",
      text: "Yours! We issue micro title deeds to asset owners that means you posses all the perks of a full landlord. Only difference is that unlike a full landlord you cannot live in the asset.",
    },
    {
      header: "Do I need a wallet and other crypto mumbo jumbo?",
      text: "No. Everything is done on our platform. You do not need any crypto experience whatsoever. All you need to do is know how to login to an account!",
    },
    {
      header: "How long is the holding period?",
      text: "There is no holding period! You can flip your share the moment you purchase it, though as a rule of thumb, when dealing with real estate it is advisable that you hold for at least a rental accrual period (varies by property). ",
    },
    {
      header: "What is KYC/AML?",
      text: `KYC means Know Your Customer and sometimes Know Your Client.
      KYC or KYC check is the mandatory process of identifying and verifying the client’s identity when opening an account and periodically over time.
      In other words, we must make sure that our clients are genuinely who they claim to be.`,
    },
    {
      header: "Why is KYC/AML mandatory?",
      text: `KYC may be a little tedious but it is mandatory in order to protect everyone involved in a transaction. We do this to make sure that no sanctioned individual or entity can participate as this can taint the entire investment. 
      In other words, if you are about to make an investment or financial decision, make sure that you are being KYC’d! Lack of KYC is actually a major red flag for all regulators, financial institutions and investors.`,
    },
  ];
  return (
    <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
      <Container fluid className="p-0">
        <img
          style={{ width: "100%" }}
          className="my-5"
          src={require("../../assets/banner.png")}
          alt=""
        />{" "}
        <Row className="my-2">
          <Col lg={7} className="mx-auto my-4" style={{ color: "white" }}>
            <h1 style={{ fontSize: 50 }}>
              THE <span className="text-green">4 STEPS</span> PROCESS{" "}
            </h1>
          </Col>
        </Row>
        <Row
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="stepsprocesspart">
            <div className="holder">
              <div className="number">1</div>
              <div className="text">Get Verified</div>
            </div>
          </div>
          <div className="stepsprocesspart">
            <div className="holder">
              <div className="number">2</div>
              <div className="text">Click Invest</div>
            </div>
          </div>
          <div className="stepsprocesspart">
            <div className="holder">
              <div className="number">3</div>
              <div className="text">earn rental income</div>
            </div>
          </div>
          <div className="stepsprocesspart">
            <div className="holder">
              <div className="number">4</div>
              <div className="text">exit anytime</div>
            </div>
          </div>
        </Row>
        <Row className="my-2">
          <Col lg={7} className="mx-auto" style={{ color: "white" }}>
            <h1 style={{ fontSize: 50 }}>frequently asked questions </h1>
          </Col>
        </Row>
        <Row className="opensans">
          <Col lg={7} className="mx-auto">
            <Accordion className="faqaccordion">
              {FAQs.map((x, index) => (
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>{x.header}</Accordion.Header>
                  <Accordion.Body>{x.text}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
        <Row className="bg-nero py-3 my-5">
          <span
            className="text-center"
            style={{ fontSize: 50, color: "white" }}
          >
            the future of <span className="text-green">real estate</span>{" "}
            investment is <span className="text-green">now</span> and{" "}
            <span className="text-green">here</span> !
          </span>
        </Row>
        <Row className="py-5">
          <Col lg={7} className="mx-auto" style={{ color: "white" }}>
            <span style={{ fontSize: 50 }}>
              Who is <span className="text-green">WEFRACTION</span> ?
            </span>
            <p
              style={{ fontSize: 20, fontWeight: 100, lineHeight: 1.3 }}
              className="opensans py-3"
            >
              Our tokenization platform partner that is the appointed manager of
              the project that will make sure the dividends are paid, company’s
              assets are well maintained and properly rented.
            </p>
          </Col>
        </Row>
        <Row className="py-5">
          <Col lg={7} className="mx-auto" style={{ color: "white" }}>
            <span
              style={{ fontSize: 50 }}
              className="text-lg-start text-center"
            >
              Get in Touch: 
            </span>
            <div
              style={{ fontSize: 20, fontWeight: 100, lineHeight: 1.3 }}
              className="opensans py-3"
            >
              <div className="my-4">
                <a
                  className="text-white text-decoration-none"
                  href="mailto:hello@wefraction.com"
                >
                  <img
                    className="mx-3"
                    style={{ width: 30, height: 30, objectFit: "contain" }}
                    src={require("../../assets/mailicon.png")}
                  />
                  hello@wefraction.com
                </a>
              </div>
              <div className="my-4">
                <span className="text-white">
                  <img
                    className="mx-3"
                    style={{ width: 30, height: 30, objectFit: "contain" }}
                    src={require("../../assets/whatsappicon.png")}
                  />
                  +971 50 6428 266
                </span>
              </div>
              <div className="my-4">
                <span className="text-white">
                  <img
                    className="mx-3"
                    style={{ width: 30, height: 30, objectFit: "contain" }}
                    src={require("../../assets/phoneicon.png")}
                  />
                  +971 54 5550 076 
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </ConfigProvider>
  );
}
