import React, { useState, useEffect } from "react";
import Table from "./Table";
import { getAfilliateLinks } from "../../utils/api";
import { CONSTANTS } from "../../utils/global";

const AffiliateLink = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const result = await getAfilliateLinks();
    console.log(result);

    if (result.status === "success") {
      setData(result.data);
      setIsLoading(false);
    } else if (result.status === "fail") {
      setMessage(result.message);
      setIsLoading(false);
      setData([]);
    }
  };

  return (
    <div>
      <Table
        AffiliateLinks={data}
        message={message}
        fetchData={fetchData}
        isLoading={isLoading}
      />
    </div>
  );
};

export default AffiliateLink;
