import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  ConfigProvider,
  Input,
  Popconfirm,
  Space,
  Table,
  theme,
} from "antd";
import { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import axiosInstance from "../../Axios/AxiosInstance";
import Loader from "../Loader";

export default function KYCTable() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [fixedUsers, setFixedUsers] = useState([]);
  const [verifying, setVerifying] = useState(false);
  const [loading, setLoading] = useState(false);

  let handleVerify = (data) => {
    console.log("verifying ", data);

    setVerifying(true);
    axiosInstance
      .post("/kyc", { investorId: data.id })
      .then((res) => {
        if (res.data.status === "success") {
          let index = fixedUsers.indexOf(data);
          if (index === -1) return;
          setFixedUsers(fixedUsers.filter((item) => item.key !== data.key));
        } else {
          console.log("error");
          console.log(res);
          alert("Something Went Wrong!");
        }
        setVerifying(false);
      })
      .catch((error) => {
        console.error(error);
        setVerifying(false);
      });
  };
  let handleRemove = (id) => {
    console.log("verifying ", id);
  };

  useEffect(() => {
    LoadUnverifiedUsers();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: "30%",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),

      width: "20%",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      ...getColumnSearchProps("phone"),
      sorter: (a, b) => a.phone.toString().localeCompare(b.phone.toString()),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "KYC Status",
      dataIndex: "kyc",
      key: "kyc",
      sorter: (a, b) => a.kyc.localeCompare(b),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "20%",
      render: (data) => (
        <>
          <ConfigProvider theme={{ algorithm: theme.defaultAlgorithm }}>
            {data.kyc === "Unverified" ? (
              <Popconfirm
                placement="right"
                title="Verify KYC"
                description="Are you Sure ?"
                onConfirm={() => {
                  handleVerify(data);
                }}
                okText="Yes"
                cancelText="No"
              >
                {!verifying ? (
                  <button className="btn btn-outline-success rounded-0 px-4 py-2 mx-1">
                    Verify
                  </button>
                ) : (
                  <Loader height={42} />
                )}
              </Popconfirm>
            ) : (
              ""
            )}
          </ConfigProvider>
        </>
      ),
    },
  ];
  return (
    <>
      <button
        className="btn btn-success my-3"
        disabled={loading}
        onClick={() => {
          LoadUnverifiedUsers();
        }}
      >
        Refresh
      </button>
      <Table loading={loading} columns={columns} dataSource={fixedUsers} />
    </>
  );

  async function LoadUnverifiedUsers() {
    setLoading(true);
    axiosInstance
      .get("/kyc")
      .then((res) => {
        if (res.data.message) {
          setFixedUsers([]);
          setLoading(false);
          return;
        }

        setFixedUsers(
          res.data.data.map((x) => ({
            key: x.id,
            email: x.email,
            name: x.first_name + " " + x.last_name,
            id: x.id,
            phone: x.phone_number,
            kyc: x.kyc_status ? "Unverified" : "Verified",
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }
}
