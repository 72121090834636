import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { CONSTANTS } from "../../utils/global";

export default function SideBarButton({
  to,
  isOpen,
  activeicon,
  nonactiveicon,
  text,
}) {
  const location = useLocation();
  const path = location.pathname;
  const isFocused = path === to;
  return (
    <NavLink
      exact
      to={to === "/" ? "/project?id=1": to}
      style={{
        display: "flex",
        width: "100%",
        justifyContent: !isOpen ? "center" : "flex-start",
        textDecoration: "none",
      }}
    >
      {isFocused ? (
        <ActiveIcon isOpen={isOpen} src={activeicon} alt="icon3" />
      ) : (
        <NonActiveIcon isOpen={isOpen} src={nonactiveicon} alt="icon3" />
      )}
      <ItemText isFocused={isFocused} isOpen={isOpen}>
        {text}
      </ItemText>
    </NavLink>
  );
}

const ActiveIcon = styled.img`
  margin-right: ${(props) => (props.isOpen ? "30px" : "0px")};
  border-radius: 3px;
  transition-duration: 0.5s;
  margin-left: ${(props) => (props.isOpen ? "40px" : "0px")};
  padding: 10px;
  background: #262f2b;
`;
const NonActiveIcon = styled.img`
  margin-right: ${(props) => (props.isOpen ? "30px" : "0px")};
  border-radius: 3px;
  margin-left: ${(props) => (props.isOpen ? "40px" : "0px")};
  transition-duration: 0.5s;
  padding: 10px;
`;
const ItemText = styled.span`
  transition: all 0.5s ease;
  transform: scaleX(${(props) => (props.isOpen ? 1 : 0)}) translateY(6px);
  position: ${(props) => (props.isOpen ? "relative" : "absolute")};
  display: inline-block;
  font-size: 20px;
  text-decoration: none;
  color: ${(props) => (props.isFocused ? CONSTANTS.green : CONSTANTS.white)};
  font-family: "Bebas Neue";
  /* transition: all 0.5s cubic-bezier(1,0,1,.81);   */
`;
