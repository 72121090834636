/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import PropertiesTable from "./PropertiesTable";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Descriptions, Modal, Popover, Progress, Tag } from "antd";
import axiosInstance from "../../Axios/AxiosInstance";
var fileDownload = require("js-file-download");

export default function AdminViewSubmittedProperties() {
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [downloadprogresslist, setDownloadprogresslist] = useState({});

  return (
    <Container
      fluid
      style={{
        width: "100%",
      }}
    >
      <Row className="mt-5">
        <Col lg={10} className="mx-auto mt-5">
          <h1>Submitted Properties</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={10} className="mx-auto">
          <PropertiesTable setSelected={setSelectedProperty} />
        </Col>
      </Row>
      <Modal
        title="Property Details"
        open={selectedProperty !== null}
        onCancel={() => {
          setSelectedProperty(null);
        }}
        footer={[
          <Button
            type="primary"
            key={"Close"}
            onClick={() => {
              setSelectedProperty(null);
            }}
          >
            Close
          </Button>,
        ]}
        width={1000}
      >
        {selectedProperty ? (
          <Descriptions bordered>
            {Object.entries(selectedProperty).map((x) => (
              <Descriptions.Item
                span={3}
                // replace every _ with space and capitalize first letter of every word
                label={x[0].replace(/_/g, " ").replace(/\w\S*/g, (w) =>
                  w.replace(/^\w/, (c) => c.toUpperCase())
                )}

                // label={Capitalize(x[0].replace("_", " "))}
              >
                {checkDataType(x[1])}
              </Descriptions.Item>
            ))}
          </Descriptions>
        ) : (
          <></>
        )}
      </Modal>
    </Container>
  );

  function checkDataType(data) {
    if (Array.isArray(data)) {
      return data.map((item, index) => {
        if (downloadprogresslist[item] === null) {
          let x = { ...downloadprogresslist };
          x[index] = 0;
          setDownloadprogresslist(x);
        }

        let progressBar = (
          <Progress
            strokeColor={"#017047"}
            style={{ marginLeft: "10px" }}
            percent={downloadprogresslist[item]}
            size={"small"}
            steps={20}
          />
        );
        if (isValidHttpUrl(item))
          return (
            <div className="antd-theme">
              <Popover
                placement="right"
                content={progressBar}
                trigger={"click"}
              >
                <a
                  rel="noreferrer"
                  alt="file"
                  onClick={() => {
                    let x = { ...downloadprogresslist };
                    x[index] = 0;
                    setDownloadprogresslist(x);
                    axiosInstance({
                      url: item, //your url
                      method: "GET",
                      responseType: "blob", // important
                      onDownloadProgress: (progressEvent) => {
                        console.log(downloadprogresslist);
                        let x = { ...downloadprogresslist };
                        x[item] = Math.round(progressEvent.progress * 100);
                        setDownloadprogresslist(x);
                      },
                    }).then((response) => {
                      fileDownload(
                        response.data,
                        item.substring(item.lastIndexOf("/") + 1)
                      );
                      let x = { ...downloadprogresslist };
                      x[item] = 0;
                      setDownloadprogresslist(x);
                    });
                  }}
                >
                  {index + 1 + ". " + item}
                </a>
              </Popover>
            </div>
          );
        else {
          return <span>{<Tag color="#00a76a">{Capitalize(item)}</Tag>}</span>;
        }
      });
    } else {
      return data?.toString();
    }
  }

  function Capitalize(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }
}
