import { SearchOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Input, Space, Table, theme } from "antd";
import { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import axiosInstance from "../../Axios/AxiosInstance";

export default function PropertiesTable({ setSelected }) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    LoadSubmittedProperties();
  }, []);

  const handleView = (data) => {
    let fixedData = data;
    delete fixedData["id"];
    setSelected(data);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Building Name",
      dataIndex: "building_name",
      key: "building_name",
      sorter: (a, b) => a.building_name.localeCompare(b),
      ...getColumnSearchProps("building_name"),
    },
    {
      title: "City Name",
      dataIndex: "city_name",
      key: "city_name",
      sorter: (a, b) => a.city_name.localeCompare(b),
      ...getColumnSearchProps("city_name"),
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
      ...getColumnSearchProps("floor"),
      sorter: (a, b) => a.floor.localeCompare(b),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Asset Type",
      dataIndex: "asset_type",
      key: "asset_type",
      sorter: (a, b) => a.asset_type.localeCompare(b),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Size(sqft)",
      dataIndex: "size_of_asset_sqft",
      key: "size_of_asset_sqft",
      sorter: (a, b) => a.size_of_asset_sqft.localeCompare(b),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Beds",
      dataIndex: "number_of_beds",
      key: "number_of_beds",
      sorter: (a, b) => a.number_of_beds.localeCompare(b),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (data) => (
        <>
          <button
            className="btn btn-outline-success rounded-0 px-4 py-2 mx-1"
            onClick={() => {
              handleView(data);
            }}
          >
            View
          </button>
        </>
      ),
    },
  ];
  return (
    <>
      <button
        className="btn btn-success my-3"
        disabled={loading}
        onClick={() => {
          LoadSubmittedProperties();
        }}
      >
        Refresh
      </button>
      <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
        <Table loading={loading} columns={columns} dataSource={properties} />
      </ConfigProvider>
    </>
  );

  async function LoadSubmittedProperties() {
    setLoading(true);
    axiosInstance
      .get("/ad/properties")
      .then((res) => {
        if (res.data.message) {
          setProperties([]);
          setLoading(false);
          return;
        }
        if (res.data.status === "success") {
          setProperties(res.data.data);
        } else {
          console.log("error");
          console.log(res.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }
}
