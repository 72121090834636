import React, { useEffect, useState } from "react";
import VerifyKYC from "./VerifyKYC";
import { Col, Container, Nav, Row, Tab, Tabs } from "react-bootstrap";
import TransferNFTs from "./TransferNFTs";
import AdminSubmitProperty from "./AdminSubmitProperty";
import AdminViewSubmittedProperties from "./AdminViewSubmittedProperties";
import { ConfigProvider, theme } from "antd";
import AdminBankTranfer from "./AdminBankTranfer";

export default function AdminPage() {
  return (
    <div className="local-bootstrap">
      <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
          <Row className="mt-5" style={{ color: "white" }}>
            <Col sm={8} className="mx-auto">
              <Nav variant="pills">
                <Nav.Item className="mx-4">
                  <Nav.Link eventKey="1">VERIFY KYC</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item className="mx-4">
                  <Nav.Link eventKey="2">Transfer NFTs</Nav.Link>
                </Nav.Item> */}
                <Nav.Item className="mx-4">
                  <Nav.Link eventKey="3">View Properties</Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-4">
                  <Nav.Link eventKey="4">Tokenize Estate</Nav.Link>
                </Nav.Item>
                <Nav.Item className="mx-4">
                  <Nav.Link eventKey="5">Bank Transfer Requests</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="1">
                  <VerifyKYC />
                </Tab.Pane>
                <Tab.Pane eventKey="2">
                  <TransferNFTs />
                </Tab.Pane>
                <Tab.Pane eventKey="3">
                  <AdminViewSubmittedProperties />
                </Tab.Pane>
                <Tab.Pane eventKey="4">
                  <AdminSubmitProperty />
                </Tab.Pane>
                <Tab.Pane eventKey="5">
                  <AdminBankTranfer />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </ConfigProvider>
    </div>
  );
}
