import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import icon1 from "../../assets/Group1.png";
import storeicon from "../../assets/storeicon.png";
import Activestoreicon from "../../assets/storeiconactive.png";
import icon4 from "../../assets/Group2.svg";
import icon3 from "../../assets/Property.png";
import icon2 from "../../assets/Vector-(1).svg";
import ActiveIcon1 from "../../assets/Vector.svg";
import ActiveIcon2 from "../../assets/activeAffil.svg";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import styles from "./NavigationTabs.module.css";

const NavigationTabs = () => {
  const userRole = useSelector((state) => state.userRole);

  const location = useLocation();
  const path = location.pathname.substr(1);

  const BrokerSideBar = () => {
    return (
      <div className={styles.sideMap}>
        <NavLink
          exact
          to="/"
          className={classNames(path === "" ? styles.active : styles.link)}
        >
          <div className={styles.icon}>
            {path === "" ? (
              <img src={Activestoreicon} alt="icon3" />
            ) : (
              <img src={storeicon} alt="icon3" />
            )}
          </div>
        </NavLink>
        <NavLink
          exact
          to="/submit-property"
          className={classNames(
            path === "submit-property" ? styles.active : styles.link
          )}
        >
          <div className={styles.icon}>
            {path === "submit-property" ? (
              <img src={ActiveIcon1} alt="icon3" />
            ) : (
              <img src={icon3} alt="icon3" />
            )}
          </div>
        </NavLink>
        <NavLink
          to="/affiliate"
          className={classNames(
            path === "affiliate" ? styles.active : styles.link
          )}
        >
          <div className={styles.icon}>
            {path === "affiliate" ? (
              <img src={ActiveIcon2} alt="icon3" />
            ) : (
              <img src={icon2} alt="icon3" />
            )}
          </div>
        </NavLink>
      </div>
    );
  };

  const AdminSideBar = () => {
    return (
      <div className={styles.sideMap}>
        <NavLink
          exact
          to="/"
          className={classNames(path === "" ? styles.active : styles.link)}
        >
          <div className={styles.icon}>
            {path === "" ? (
              <img src={Activestoreicon} alt="icon3" />
            ) : (
              <img src={storeicon} alt="icon3" />
            )}
          </div>
        </NavLink>
        <NavLink
          exact
          to="/admin"
          className={classNames(path === "admin" ? styles.active : styles.link)}
        >
          <div className={styles.icon}>
            {path === "admin" ? (
              <img src={ActiveIcon1} alt="icon3" />
            ) : (
              <img src={icon3} alt="icon3" />
            )}
          </div>
        </NavLink>
      </div>
    );
  };

  const InvestorSideBar = () => {
    return (
      <div className={styles.sideMap}>
        <NavLink
          exact
          to="/"
          className={classNames(path === "" ? styles.active : styles.link)}
        >
          <div className={styles.icon}>
            {path === "" ? (
              <img src={Activestoreicon} alt="icon3" />
            ) : (
              <img src={storeicon} alt="icon3" />
            )}
          </div>
        </NavLink>
        <NavLink
          exact
          to="/myassets"
          className={classNames(
            path === "myassets" ? styles.active : styles.link
          )}
        >
          <div className={styles.icon}>
            {path === "myassets" ? (
              <img src={ActiveIcon1} alt="icon3" />
            ) : (
              <img src={icon3} alt="icon3" />
            )}
          </div>
        </NavLink>
      </div>
    );
  };

  const NotLoggedSideBar = () => {
    return (
      <div className={styles.sideMap}>
        <NavLink
          exact
          to="/"
          className={classNames(path === "" ? styles.active : styles.link)}
        >
          <div className={styles.icon}>
            {path === "" ? (
              <img src={Activestoreicon} alt="icon3" />
            ) : (
              <img src={storeicon} alt="icon3" />
            )}
          </div>
        </NavLink>
      </div>
    );
  };

  const isAdmin = () => (userRole === "admin" ? true : false);
  const isBroker = () => (userRole === "broker" ? true : false);
  const isInvestor = () => (userRole === "investor" ? true : false);

  const token = localStorage.getItem("token");

  let ItemSideBar = <NotLoggedSideBar />;

  if (isBroker()) {
    ItemSideBar = <BrokerSideBar />;
  } else if (isInvestor()) {
    ItemSideBar = <InvestorSideBar />;
  } else if (isAdmin()) {
    ItemSideBar = <AdminSideBar />;
  }

  return (
    path !== "login" &&
    path !== "register" && <div className={styles.container}>{ItemSideBar}</div>
  );
};

export default NavigationTabs;
