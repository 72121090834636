const { default: BASE_URL } = require("./config");

const CONSTANTS = {
  green: "#00A76A",
  white: "#FFFFFF",
  black: "#212121",
  brown: "603813",
  grey:"#b4b4b4",

  BASE_URL,
  FRONTEND_URL: process.env.NODE_ENV === "production" ? "http://stagingplatform.wefraction.com" : "http://localhost:3000",
};

module.exports = {
  CONSTANTS,
};
