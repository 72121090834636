/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./LandingPage.scss";
import ItemCarousel from "./ItemCarousel";
import jwtDecode from "jwt-decode";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Alert,
  Button,
  ConfigProvider,
  Descriptions,
  Dropdown,
  Input,
  InputNumber,
  Modal,
  Popover,
  Progress,
  Space,
  Steps,
  Upload,
  theme,
} from "antd";
import axiosInstance from "../../Axios/AxiosInstance";
import fileDownload from "js-file-download";
import axios from "axios";
import { Buffer } from "buffer";
import Loader from "../Loader";
import Contactus from "../ContactUs/Contactus";
import BASE_URL from "../../utils/config";

const NAME = "WEFRACTION";
const IBAN = "AE070331234567890123456";

export default function LandingPage() {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id") || "2";
  let [propertyData, setPropertyData] = useState(null);
  let [BrochureImages, setBrochureImages] = useState([]);
  const [isKYCedGlobal, setisKYCedGlobal] = useState(false);
  const [doingPayment, setDoingPayment] = useState(false);
  let token = localStorage.getItem("token");
  let jwt = token ? jwtDecode(token) : null;

  let isLoggedIn = token ? true : false;

  let doKYCLink = (
    <a
      style={{ textDecoration: "none", color: "white", fontWeight: 600 }}
      href="/kyc"
    >
      {"[Begin KYC]"}
    </a>
  );
  let LoginLink = (
    <a
      style={{ textDecoration: "none", color: "white", fontWeight: 600 }}
      href="/login"
    >
      {"[Login To View]"}
    </a>
  );

  const [walletBalance, setWalletBalance] = useState(4000);
  const [tokenPrice, setTokenPrice] = useState(3000);
  const [DownloadableContent, setDownloadableContent] = useState([]);
  const [loadingPropertyData, setLoadingPropertyData] = useState(false);
  const [Role, setRole] = useState(jwt ? jwt.UserInfo.roles : "");

  const [ticketsLeft, setTicketsLeft] = useState(null);

  const [paymentMethod, setPaymentMethod] = useState("bank_transfer");
  const [paymentProof, setPaymentProof] = useState([]);
  const [isPaymentButtonDisabled, setIsPaymentButtonDisabled] = useState(true);
  const [downloadprogresslist, setDownloadprogresslist] = useState({});
  const [fixedList, setFixedList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalCongrats, setModalCongrats] = useState(false);
  const [numberOfTokensToBuy, setNumberOfTokensToBuy] = useState(1);

  const handleChangePaymentProof = (info) => {
    let newFileList = [...info.fileList];
    setPaymentProof(newFileList);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const bankStepsStyle = {
    textAlign: "start",
    border: "0",
    marginTop: 16,
    controlItemBgActive: "#00a76a",
  };
  const steps = [
    {
      title: "Choose Amount",
      content: (
        <Container>
          <Row>
            <Descriptions bordered className="my-2">
              <Descriptions.Item label="Ticket Price" span={3}>
                {tokenPrice}
              </Descriptions.Item>
              <Descriptions.Item label="Total" span={3}>
                {tokenPrice * numberOfTokensToBuy}
              </Descriptions.Item>
            </Descriptions>
          </Row>
          <Row>
            <p className="me-5" style={{ fontSize: 20 }}></p>
            <InputNumber
              addonBefore={"Number Of Tickets"}
              min={1}
              max={ticketsLeft}
              defaultValue={numberOfTokensToBuy}
              onChange={(value) => {
                setNumberOfTokensToBuy(value);
              }}
            />
            {ticketsLeft ? (
              <Alert
                className="my-2"
                description={ticketsLeft + " Tickets Left"}
                type="info"
                showIcon
              />
            ) : (
              ""
            )}
          </Row>
        </Container>
      ),
    },
    {
      title: "Payment Info",
      content: (
        <div>
          <Descriptions bordered className="my-2">
            <Descriptions.Item label="IBAN" span={3} className="">
              {IBAN}
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(IBAN);
                }}
                className="border-0 bg-transparent shadow-none"
              >
                <img src={require("../../assets/ClipboardIcon.png")} />
              </Button>
            </Descriptions.Item>
            <Descriptions.Item label="NAME" span={3} className="">
              {NAME}
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(NAME);
                }}
                className="border-0 bg-transparent shadow-none"
              >
                <img src={require("../../assets/ClipboardIcon.png")} />
              </Button>
            </Descriptions.Item>
            <Descriptions.Item label="AMOUNT" span={3} className="">
              {tokenPrice * numberOfTokensToBuy} $
            </Descriptions.Item>
            <Descriptions.Item label="TRANSFER NOTE" span={3} className="">
              <span style={{ fontWeight: 100 }} className="opensans">
                {"WF-" +
                  propertyData?.developer_marketing_material +
                  "-" +
                  propertyData?.id +
                  "-" +
                  numberOfTokensToBuy}
              </span>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(
                    "WF-" +
                      propertyData?.developer_marketing_material +
                      "-" +
                      propertyData?.id +
                      "-" +
                      numberOfTokensToBuy
                  );
                }}
                className="border-0 bg-transparent shadow-none"
              >
                <img src={require("../../assets/ClipboardIcon.png")} />
              </Button>
            </Descriptions.Item>
          </Descriptions>
          <div className="mt-5 mb-3">
            <span className="me-5" style={{ fontSize: 20 }}>
              UPLOAD PROOF
            </span>
            <Upload
              listType="picture"
              fileList={paymentProof}
              customRequest={dummyRequest}
              onChange={handleChangePaymentProof}
              maxCount={1}
            >
              <Button
                className="border-green"
                style={{
                  borderWidth: 2,
                  backgroundColor: "transparent",
                  height: 40,
                }}
              >
                <span
                  style={{
                    fontSize: 20,
                  }}
                  className="text-green"
                >
                  Upload
                </span>
                <img
                  className="mb-1 ms-3"
                  style={{ height: 20 }}
                  src={require("../../assets/upload-icon.png")}
                />
              </Button>
            </Upload>
          </div>
          <Alert
            message="Note"
            description="Please be advised that your tickets will be held in reserve status until payment is verified."
            type="warning"
            showIcon
          />
          {ticketsLeft ? (
            <Alert
              className="my-2"
              description={ticketsLeft + " Tickets Left"}
              type="info"
              showIcon
            />
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];
  const [currentStep, setCurrentStep] = useState(0);
  const next = () => {
    setCurrentStep(currentStep + 1);
  };
  const prev = () => {
    setCurrentStep(currentStep - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  useEffect(() => {
    if (paymentMethod === "crypto") {
      if (tokenPrice <= walletBalance) {
        setIsPaymentButtonDisabled(false);
      } else {
        setIsPaymentButtonDisabled(true);
      }
    } else if (paymentMethod === "bank_transfer") {
      if (paymentProof.length >= 1) {
        setIsPaymentButtonDisabled(false);
      } else {
        setIsPaymentButtonDisabled(true);
      }
    }
  }, [paymentMethod, paymentProof.length, tokenPrice, walletBalance]);

  const handleConfirmPayment = async () => {
    setDoingPayment(true);
    setTicketsLeft(null);
    let data = new FormData();
    data.append("upload_proof", paymentProof[0].originFileObj);
    data.append("project_id", id);
    data.append("accountId", "2");
    data.append("payment_type", paymentMethod);
    data.append("number_of_tickets", numberOfTokensToBuy);

    let res = await axiosInstance.post("/invest", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(res);
    if (res.data.message) {
      console.log(res.data.ticket_available);
      setTicketsLeft(res.data.ticket_available);
    } else if (res.data.status === "success") {
      setPaymentProof([]);
      setOpenModal(false);
      setCurrentStep(0);
      setModalCongrats(true);
    }
    console.log(Array.from(data.entries()));
    setDoingPayment(false);
  };

  useEffect(() => {
    const getDownloadableContent = async () => {
      let tempo = [
        "http://35.172.63.222:5000/s3/bc6826f9-3d32-4978-988f-5fd2ac493c6f_Tranquil Brochure.pdf",
        "http://35.172.63.222:5000/s3/fef2b11a-a6d3-4223-993f-1e3ded3bfdec_WF_WellCube simulation.xlsx",
      ];
      for (let i = 0; i < tempo.length; i++) {
        const item = tempo[i];
        if (tempo[item] === null) {
          let x = { ...tempo };
          x[i] = 0;
          tempo = x;
        }
      }

      let temp = tempo.map((item, index) => {
        if (downloadprogresslist[item] === null) {
          let x = { ...downloadprogresslist };
          x[index] = 0;
          setDownloadprogresslist(x);
        }

        let progressBar = (
          <Progress
            strokeColor={"#017047"}
            style={{ marginLeft: "10px" }}
            percent={downloadprogresslist[item]}
            size={"small"}
            steps={20}
          />
        );
        return (
          <div className="antd-theme">
            <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
              <Popover
                placement="right"
                content={progressBar}
                trigger={"click"}
              >
                <a
                  rel="noreferrer"
                  alt="file"
                  onClick={() => {
                    let x = { ...downloadprogresslist };
                    x[index] = 0;
                    setDownloadprogresslist(x);
                    axiosInstance({
                      url: item,
                      method: "GET",
                      responseType: "blob",
                      onDownloadProgress: (progressEvent) => {
                        let x = { ...downloadprogresslist };
                        x[item] = Math.round(progressEvent.progress * 100);
                        setDownloadprogresslist(x);
                      },
                    }).then((response) => {
                      fileDownload(
                        response.data,
                        item.substring(item.lastIndexOf("/") + 1)
                      );
                      let x = { ...downloadprogresslist };
                      x[item] = 0;
                      setDownloadprogresslist(x);
                    });
                  }}
                >
                  {index === 0
                    ? "Tranquil Full Broshure"
                    : "Tranquil Investor Simulation"}
                </a>
              </Popover>
            </ConfigProvider>
          </div>
        );
      });
      if (temp.length === 0) {
        setFixedList(<p>No Files</p>);
      } else {
        setFixedList(temp);
      }
    };
    getDownloadableContent();
  }, [DownloadableContent, downloadprogresslist]);

  useEffect(() => {
    getPropertyData(id, jwt);
  }, []);

  if (!id) {
    //id not found in URL
    // navigate("/");
    return;
  }

  return (
    <div className="local-bootstrap">
      <Container fluid className="text-center">
        <Row className="py-5 d-lg-flex d-none"></Row>
        <Row className="bg-nero py-3">
          <Col lg={7} xs={10} className="mx-auto">
            <Row>
              <Col lg={6} xs={8} className="mx-auto my-2 my-lg-0">
                <span style={{ color: "white", fontWeight: 300, fontSize: 20 }}>
                  REAL ESTATE INNOVATION:{" "}
                  <span className="text-green">TOKENIZED OWNERSHIP !</span>
                </span>
              </Col>
              <Col lg={6} xs={8} className="mx-auto my-2 my-lg-0">
                <span style={{ color: "white", fontWeight: 300, fontSize: 20 }}>
                  <span className="text-green">OWN</span> YOUR SHARE OF THE
                  ASSET, <span className="text-green">GET PASSIVE REVENUE</span>
                  , <span className="text-green">TRADE </span> IT ANYTIME !
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={8} className="mx-auto my-4">
            <Row>
              <Col lg={3} className="me-4">
                {loadingPropertyData ? (
                  <Loader width={100} height={100} />
                ) : (
                  <>
                    <Row>
                      <Col className="bg-nero pt-3">
                        <Row className="py-2">
                          <span style={{ color: "white", fontSize: 28 }}>
                            {/* Welcome from {localStorage.getItem("yourBroker")} */}
                            Welcome from Imobiliare Dubai
                          </span>
                        </Row>
                        <Row>
                          <ul
                            className="listgreenarrows opensans mx-2"
                            style={{ fontWeight: 300, fontSize: 14 }}
                          >
                            <li>Over 3000 Real Estate Transactions</li>
                            <li>Over 2600 Units Sold</li>
                            <li>Located in Dubai, Bucharest</li>
                            {localStorage.getItem("yourAgent") ? (
                              <li>
                                Your Agent: {localStorage.getItem("yourAgent")}
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col className="p-lg-0 m-lg-0">
                        <img
                          className="p-0 m-0"
                          src={require("../../assets/imobiliare.jpeg")}
                          alt="team"
                          style={{ width: "100%", objectFit: "contain" }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
              <Col className="bg-nero pt-3 my-3 my-lg-0">
                {loadingPropertyData ? (
                  <Loader width={100} height={100} />
                ) : (
                  <Row className="py-2">
                    <Col lg={5}>
                      <Row>
                        <span
                          style={{
                            color: "white",
                            fontSize: 24,
                            textAlign: "left",
                          }}
                        >
                          Dubai's First Fractionalized Project
                        </span>
                      </Row>
                      <Row>
                        <Row className="pt-2">
                          <ul
                            className="listgreenarrows opensans mx-2"
                            style={{ fontWeight: 300, fontSize: 14 }}
                          >
                            <li>
                              <span style={{ fontWeight: 700 }}>
                                Location :{" "}
                              </span>
                              {/* {propertyData?.city_name} */}
                              JVT, Dubai
                            </li>
                            <li>
                              <span style={{ fontWeight: 700 }}>
                                Unit Type :{" "}
                              </span>
                              {/* {propertyData?.asset_type} */}
                              Hotel Apartments
                            </li>
                            <li>
                              <span style={{ fontWeight: 700 }}>
                                Units Available:{" "}
                              </span>
                              {/* {propertyData?.area} */}
                              Studio, 1 Bed, 2 Bed
                            </li>

                            <li>
                              <span style={{ fontWeight: 700 }}>
                                Net Yield :{" "}
                              </span>
                              {/* ? propertyData?.gross_yeald + " %" */}
                              {isLoggedIn
                                ? isKYCedGlobal
                                  ? "6%"
                                  : doKYCLink
                                : LoginLink}
                            </li>
                            <li>
                              <span style={{ fontWeight: 700 }}>
                                Dividends Payment :{" "}
                              </span>
                              {/* ? propertyData?.dividends_payments */}
                              {isLoggedIn
                                ? isKYCedGlobal
                                  ? "Yearly"
                                  : doKYCLink
                                : LoginLink}
                            </li>
                          </ul>
                        </Row>
                      </Row>
                      <Row
                        className="text-start opensans ps-2 my-3"
                        style={{
                          color: "white",
                          fontSize: 14,
                          fontWeight: 600,
                        }}
                      >
                        <span className="py-1 temp-bigger">
                          <span className="text-green">
                            Investment Ticket:{" "}
                          </span>{" "}
                          {/* ? propertyData?.funding_value */}
                          {isLoggedIn
                            ? isKYCedGlobal
                              ? "$6,000"
                              : doKYCLink
                            : LoginLink}
                        </span>
                        <span className="py-1 temp-bigger">
                          <span className="text-green">Available Tickets:</span>{" "}
                          {/* ? propertyData?.minimum_investment */}
                          {isLoggedIn
                            ? isKYCedGlobal
                              ? "100"
                              : doKYCLink
                            : LoginLink}
                        </span>
                      </Row>
                    </Col>
                    <Col>
                      <Row
                        className="text-green text-center"
                        style={{ fontSize: 28 }}
                      >
                        <p className="">Tranquil: The Urban Nirvana</p>
                      </Row>
                      <Row
                        className="text-green text-center"
                        style={{ fontSize: 28 }}
                      >
                        <Col lg={7} className="mx-auto">
                          <ItemCarousel images={BrochureImages} />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={8}
                          className="mx-auto"
                          style={{ color: "white" }}
                        >
                          <ul
                            className="connectedlist"
                            style={{ listStyle: "inside", textAlign: "start" }}
                          >
                            <li className="py-3">Jan 2024 Delivery Date</li>
                            <li>
                              {/* {propertyData?.size_of_asset_sqft} sqft /{" "}
                              {Math.round(
                                propertyData?.size_of_asset_sqft * 0.0929
                              ) + " "} */}
                              Average unit size: 629 sqft
                            </li>
                            <li>Property Managment: included</li>
                            <li className="pb-0">
                              Guaranteed Returns: Five Years
                            </li>
                          </ul>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={9} className="mx-auto">
                          <Row>
                            <Col lg={2} className="m-0 px-1">
                              {DownloadableContent.length > 0 ? (
                                <Popover
                                  trigger={"click"}
                                  placement="bottom"
                                  content={fixedList}
                                >
                                  <button
                                    className="border-0 rounded-0 py-2 px-3 my-2 my-lg-0"
                                    style={{
                                      backgroundColor: "#4D4D4D",
                                      color: "white",
                                      fontSize: 24,
                                    }}
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <Space>PDF</Space>
                                  </button>
                                </Popover>
                              ) : (
                                ""
                              )}
                            </Col>
                            <Col>
                              {true &&
                                console.log(isLoggedIn, isKYCedGlobal, Role)}
                              <button
                                disabled={
                                  !(isLoggedIn && isKYCedGlobal) ||
                                  Role !== "investor"
                                }
                                className="green-button bg-green rounded-0 py-2"
                                style={{
                                  color: "white",
                                  fontSize: 24,
                                  width: "100%",
                                }}
                                onClick={() => {
                                  navigate("/list");
                                }}
                              >
                                INVEST IN TRANQUIL
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Contactus />
    </div>
  );

  function getFileNameFromURL(filename) {
    return filename
      .substring(filename.lastIndexOf("/") + 1)
      .replace(/\.[^/.]+$/, "");
  }

  async function getPropertyData(id, token) {
    setLoadingPropertyData(true);

    let headers = { authorization: localStorage.getItem("token") };
    if (!token) {
      headers = null;
    }
    let response = await axios.get(BASE_URL + "/property/" + id, {
      headers,
    });
    let data = response.data;
    console.log("property pulled");
    console.log(data);
    if (data.status === "success") {
      let images = data.data.developer_brochure;
      setBrochureImages(images);
      setPropertyData(data.data);
      console.log("token here 1");
      console.log(data.data);
      if (data.data.kyc === "Verified") {
        setisKYCedGlobal(true);
        setDownloadableContent(data.data.downloadable_roi);
      }
    } else {
      //id not available
      // navigate("/");
    }
    setLoadingPropertyData(false);
  }

  async function DoDummyRequest() {
    let data = new FormData();
    data.append("upload_proof", {});
    data.append("project_id", id);
    data.append("accountId", propertyData.accountId);
    data.append("payment_type", "bank_transfer");
    data.append("number_of_tickets", 20000);

    let res = await axiosInstance.post("/invest", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(res.data.ticket_available);
    setTicketsLeft(res.data.ticket_available);
  }
}
