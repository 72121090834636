import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import icon1 from "../../assets/Group1.png";
import storeicon from "../../assets/storeicon.png";
import Activestoreicon from "../../assets/storeiconactive.png";
import icon4 from "../../assets/Group2.svg";
import icon3 from "../../assets/Property.png";
import icon2 from "../../assets/Vector-(1).svg";
import image from "../../assets/WeFraction-Logo.svg";
import styles from "./Sidebar.module.css";
import SmLogo from "../../assets/WeFractionSmallLogo.svg";
import ActiveIcon1 from "../../assets/Vector.svg";
import ActiveIcon2 from "../../assets/activeAffil.svg";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import useWindowDimensions from "../../utils/useWindowDimensions";
import styled from "styled-components";
import SideBarButton from "./SideBarButton";
import { Container, Row } from "react-bootstrap";

const Sidebar = ({ children }) => {
  const userRole = useSelector((state) => state.userRole);
  // const [isOpen, setIsOpen] = useState(false);
  const isOpen = useSelector((state) => state.isOpen);
  const [activeLink, setActiveLink] = useState("");
  const toggle = () => dispatch({ type: "menuTrigger", isOpen: !isOpen });

  const dispatch = useDispatch();

  useEffect(() => {
    // TransformStream();

    if (document.documentElement.clientWidth < 1024) {
      dispatch({ type: "menuTrigger", isOpen: false });
      // setIsOpen(false);
    }
    const resizeListener = () => {
      if (document.documentElement.clientWidth < 1024) {
        dispatch({ type: "menuTrigger", isOpen: false });
      }
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  const location = useLocation();
  const path = location.pathname.substr(1);

  // if (userRole === "investor") {
  //   return null;
  // }

  const BrokerSideBar = () => {
    return (
      <LinksContainer>
        <SideBarButton
          activeicon={Activestoreicon}
          nonactiveicon={storeicon}
          isOpen={isOpen}
          text={"View Other Listed Properties"}
          to={"/"}
        />
        <SideBarButton
          activeicon={ActiveIcon1}
          nonactiveicon={icon3}
          isOpen={isOpen}
          text={"Submit A Property"}
          to={"/submit-property"}
        />
        <SideBarButton
          activeicon={ActiveIcon2}
          nonactiveicon={icon2}
          isOpen={isOpen}
          text={"Create Affiliate Links"}
          to={"/affiliate"}
        />
      </LinksContainer>
    );
  };

  const AdminSideBar = () => {
    return (
      <LinksContainer>
        <SideBarButton
          activeicon={Activestoreicon}
          nonactiveicon={storeicon}
          isOpen={isOpen}
          text={"Listed Properties"}
          to={"/"}
        />
        <SideBarButton
          activeicon={ActiveIcon1}
          nonactiveicon={icon3}
          isOpen={isOpen}
          text={"Dashboard"}
          to={"/admin"}
        />
      </LinksContainer>
    );
  };

  const InvestorSideBar = () => {
    return (
      <LinksContainer>
        <SideBarButton
          activeicon={Activestoreicon}
          nonactiveicon={storeicon}
          isOpen={isOpen}
          text={"Listed Properties"}
          to={"/"}
        />
        <SideBarButton
          activeicon={ActiveIcon1}
          nonactiveicon={icon3}
          isOpen={isOpen}
          text={"My Assets"}
          to={"/myassets"}
        />
      </LinksContainer>
    );
  };

  const NotLoggedSideBar = () => {
    return (
      <LinksContainer isOpen={isOpen}>
        <SideBarButton
          activeicon={Activestoreicon}
          nonactiveicon={storeicon}
          isOpen={isOpen}
          to={"/"}
          text={"Listed Properties"}
        />
      </LinksContainer>
    );
  };

  const isAdmin = () => (userRole === "admin" ? true : false);
  const isBroker = () => (userRole === "broker" ? true : false);
  const isInvestor = () => (userRole === "investor" ? true : false);

  const token = localStorage.getItem("token");

  let ItemSideBar = <NotLoggedSideBar />;

  if (isBroker()) {
    ItemSideBar = <BrokerSideBar />;
  } else if (isInvestor()) {
    ItemSideBar = <InvestorSideBar />;
  } else if (isAdmin()) {
    ItemSideBar = <AdminSideBar />;
  }

  return (
    path !== "login" &&
    path !== "register" && (
      <div className={styles.container}>
        <div
          style={{
            width: isOpen ? "20rem" : "7.2rem",
            paddingRight: 5,
            transition: "all 0.5s ease",
          }}
          className={styles.sidebar}
        >
          <Container>
            <Row className="my-4"></Row>
            <Row className="my-4"></Row>
            <Row className="my-4"></Row>
          </Container>
          <div className={styles.sideContainer}>{ItemSideBar}</div>
        </div>
        {/* <main className={styles.main}>{children}</main> */}
      </div>
    )
  );
};

export default Sidebar;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease;
  align-items: ${(props) => (props.isOpen ? "start" : "center")};
  gap: 2rem;
`;
