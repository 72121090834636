import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

export default function TransferNFTs() {
  const [from, setFrom] = useState("none");
  const [nft, setNFT] = useState("none");
  const [to, setTo] = useState("none");

  const [loading, setLoading] = useState(false);
  return (
    <Container style={{ fontSize: 40 }} className="text-center">
      <Row className="my-5"></Row>
      <Row>
        <Col>
          <p>From</p>
          <Form.Select
            disabled={loading}
            size="lg"
            aria-label="Default select example"
            onChange={(e) => {
              setFrom(e.target.value);
              if (e.target.value) {
                setNFT("none");
              }
            }}
            value={from}
          >
            <option value="none">SELECT INVESTOR/BROKER</option>
            <option value="1">Investor 1</option>
            <option value="2">Investor 2</option>
            <option value="3">Investor 3</option>
          </Form.Select>
        </Col>
        <Col>
          <p>NFT</p>
          <Form.Select
            disabled={from === "none" || loading}
            size="lg"
            aria-label="Default select example"
            onChange={(e) => {
              setNFT(e.target.value);
            }}
            value={nft}
          >
            <option value="none">SELECT NFT</option>
            <option value="1">NFT 1</option>
            <option value="2">NFT 2</option>
            <option value="3">NFT 3</option>
          </Form.Select>
        </Col>
        <Col>
          <p>To</p>
          <Form.Select
            disabled={loading}
            size="lg"
            aria-label="Default select example"
            onChange={(e) => {
              setTo(e.target.value);
            }}
            value={to}
          >
            <option value={"none"}>SELECT INVESTOR/BROKER</option>
            <option value="1">INVESTOR 1</option>
            <option value="2">BROKER 2</option>
            <option value="3">BROKER 5</option>
          </Form.Select>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <button
            disabled={
              loading || from === "none" || to === "none" || nft === "none"
            }
            style={{ fontSize: 40 }}
            className="btn btn-success"
            onClick={doTransfer}
          >
            {!loading ? "TRANSFER NFT" : "Loading..."}
          </button>
        </Col>
      </Row>
    </Container>
  );

  async function doTransfer() {
    setLoading(true);
    setTimeout(() => {
      setFrom("none");
      setNFT("none");
      setTo("none");
      setLoading(false);
    }, 3000);
  }
}
