import React from "react";
import styles from "./CardList.module.css";
import IconLocation from "../../assets/location.svg";
import studio from "../../assets/studio.jpeg";
import onebed from "../../assets/1bed.jpeg";
import twobed from "../../assets/2bed.jpeg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import styled from "@emotion/styled";
import { CONSTANTS } from "../../utils/global";
import { Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export const CardList = ({ data, isLoading, setOpenModal }) => {
  const isOpen = useSelector((state) => state.isOpen);

  var Css = styles.menuOpen;
  var Css1 = styles.menuOpen1;
  if (isOpen == false) {
    Css = styles.menuClose;
    Css1 = styles.menuClose1;
  }

  return (
    <Container fluid>
      <Row className="ms-0 ms-lg-5 mt-5">
        <Col className="mx-auto" xs={12} md={12} lg={10}>
          <Row>
            <Col xl={4} md={6} xs={12} className="my-3">
              <div to={`/project?id=1`} style={{ textDecoration: "none" }}>
                <div className={styles.cardList}>
                  <div
                    style={{
                      height: 300,
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={studio}
                      alt="image_list"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div className={styles.details_container}>
                    <div>
                      {/* <PropertyName>{item.building_name}</PropertyName> */}
                      <PropertyName>ZEN NIDO</PropertyName>
                      <LocationName>Studio</LocationName>
                      <Description>
                        Nido in Spanish means NEST. This is a cozy comfortable
                        apartment designed with the trend of minimalistic
                        living. But we promise to provide the same elements of
                        Wellness for the Mobile Millennials and forward looking
                        people
                      </Description>
                      {/* <LocationName>{item.city_name}</LocationName> */}
                      <Investment>$ 6000</Investment>
                    </div>
                  </div>
                  <button
                    disabled={false}
                    className="green-button bg-green rounded-0 py-2"
                    style={{
                      color: "white",
                      fontSize: 24,
                      width: "90%",
                      margin: "0 auto 10px",
                      backgroundColor: "#00a76a",
                      border: "none",
                      outline: "transparent",
                      fontFamily: "Bebas Neue",
                    }}
                    onClick={() => setOpenModal(true)}
                  >
                    INVEST IN TRANQUIL
                  </button>
                </div>
              </div>
            </Col>
            <Col xl={4} md={6} xs={12} className="my-3">
              <div to={`/project?id=1`} style={{ textDecoration: "none" }}>
                <div className={styles.cardList}>
                  <div
                    style={{
                      height: 300,
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={onebed}
                      alt="image_list"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div className={styles.details_container}>
                    <div>
                      {/* <PropertyName>{item.building_name}</PropertyName> */}
                      <PropertyName>AURA AGIL</PropertyName>
                      <LocationName>1 bed</LocationName>
                      <Description>
                        In line with the trend to be flexible and adaptable, the
                        Agil convertible 1 bedroom apartments are our answer to
                        give that extra freedom to our patrons. Change your room
                        to a therapy room, office room, exercise room or a yoga
                        room and reclaim it when needed as a bedroom.
                      </Description>
                      {/* <LocationName>{item.city_name}</LocationName> */}
                      <Investment>$ 6000</Investment>
                    </div>
                  </div>
                  <button
                    disabled={false}
                    className="green-button bg-green rounded-0 py-2"
                    style={{
                      color: "white",
                      fontSize: 24,
                      width: "90%",
                      margin: "0 auto 10px",
                      backgroundColor: "#00a76a",
                      border: "none",
                      outline: "transparent",
                      fontFamily: "Bebas Neue",
                    }}
                    onClick={() => setOpenModal(true)}
                  >
                    INVEST IN TRANQUIL
                  </button>
                </div>
              </div>
            </Col>
            <Col xl={4} md={6} xs={12} className="my-3">
              <div to={`/project?id=1`} style={{ textDecoration: "none" }}>
                <div className={styles.cardList}>
                  <div
                    style={{
                      height: 300,
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={twobed}
                      alt="image_list"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div className={styles.details_container}>
                    <div>
                      {/* <PropertyName>{item.building_name}</PropertyName> */}
                      <PropertyName>AURA AGIL 2</PropertyName>
                      <LocationName>2 Bed</LocationName>
                      <Description>
                        In line with the trend to be flexible and adaptable, the
                        Agil convertible 2 bedroom apartments are our answer to
                        give that extra freedom to our patrons. Change your room
                        to a therapy room, office room, exercise room or a yoga
                        room and reclaim it when needed as a bedroom.
                      </Description>
                      {/* <LocationName>{item.city_name}</LocationName> */}
                      <Investment>$ 6000</Investment>
                    </div>
                  </div>
                  <button
                    disabled={false}
                    className="green-button bg-green rounded-0 py-2"
                    style={{
                      color: "white",
                      fontSize: 24,
                      width: "90%",
                      margin: "0 auto 10px",
                      backgroundColor: "#00a76a",
                      border: "none",
                      outline: "transparent",
                      fontFamily: "Bebas Neue",
                    }}
                    onClick={() => setOpenModal(true)}
                  >
                    INVEST IN TRANQUIL
                  </button>
                </div>
              </div>
            </Col>

            {/* {data?.map((item, index) => (
              <>
                <Col key={index} xl={4} md={6} xs={12} className="my-3">
                  <div
                    to={`/project?id=${item.id}`}
                    style={{ textDecoration: "none" }}
                    key={item.id}
                  >
                    <div key={item.id} className={styles.cardList}>
                      <div
                        style={{
                          height: 300,
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                          width: "100%",
                          cursor: "pointer",
                        }}
                        onClick={() => setOpenModal(true)}
                      >
                        <img
                          src={item.developer_brochure[0]}
                          alt="image_list"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                      <div className={styles.details_container}>
                        <div>
                          <PropertyName>{item.building_name}</PropertyName>
                          <LocationName>{item.city_name}</LocationName>
                          <Description>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Velit rerum adipisci alias quis omnis fuga
                            odit ipsum, dolorum quas natus.
                          </Description>
                          <Investment>
                            $ {item.minimum_investment.toLocaleString()}
                          </Investment>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            ))} */}
            {isLoading === true && (
              <div className={styles.loading}>
                <Loader />
              </div>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const PropertyName = styled.p`
  font-size: 40px;
  padding-top: 12px;
  padding-bottom: 0px;
  font-weight: 500;
  padding-left: 20px;
  color: #fff;
  margin-bottom: 0px;
`;

const LocationName = styled.p`
  padding-left: 20px;
  padding-top: 0px;
  padding-bottom: 10px;
  font-family: "Standerd" !important;
  font-weight: 100 !important;
  margin-bottom: 0px;
  font-size: 18px;
  color: ${CONSTANTS.green};
`;

const Investment = styled.p`
  padding-left: 20px;
  font-size: 30px;
  font-weight: 100 !important;
  color: #fff;
  margin-bottom: 20px;
  padding-top: 40px;
`;
const Description = styled.p`
  line-height: 1.8;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 19px;
  font-weight: 100 !important;
  font-family: "Standerd" !important;
  color: ${CONSTANTS.grey};
  margin-bottom: 5px;
  overflow: hidden;
`;
