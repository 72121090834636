import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import linkicon from "../../assets/activeAffil.svg";

export default function NotFound() {
  return (
    <Container
      className="local-bootstrap"
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Row>
        <Col className="text-center text-green">
          <h1 style={{ fontSize: 200 }}>404</h1>
          <h3>You seem to be lost</h3>
          <h5>Grab this</h5>
          <Link to={"/"}>
            <h5>
              <img src={linkicon} />
            </h5>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}
