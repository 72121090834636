import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BankTranferTable from "./BankTranferTable";

export default function AdminBankTranfer() {
  return (
    <Container
      fluid
      style={{
        width: "100%",
      }}
    >
      <Row>
        <Col lg="9" className="mx-auto">
          <BankTranferTable />
        </Col>
      </Row>
    </Container>
  );
}
