import axios from "axios";
import BASE_URL from "./config";
import { getCookie } from "./libs";
import axiosInstance from "../Axios/AxiosInstance";

const token = localStorage.getItem("token");

export const userLogin = async (data) => {
  const headers = { "Content-Type": "application/json" };

  try {
    const response = await axiosInstance.post("/login", data, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const userRegister = async (data) => {
  const url = `${BASE_URL}/create_account`;
  const headers = {
    "Content-Type": "application/json",
    gid: getCookie("_gid"),
  };

  try {
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const submitProperty = async (data) => {
  const url = `${BASE_URL}/submit_property`;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAfilliateLinks = async () => {
  try {
    const response = await axiosInstance.get("/affiliate_links");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getViewModalAffiliateLinks = async (id) => {
  try {
    const response = await axiosInstance.get(`/affiliate_links/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getInvestorAssets = async () => {
  try {
    const response = await axiosInstance.get("/investor/assets");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// export const deleteLinks = async (id) => {
//   const url = `${BASE_URL}/affiliate_links/${id}`;
//   const headers = {
//     Authorization: `Bearer ${token}`,
//     "Content-Type": "application/json",
//   };

//   try {
//     const response = await axios.delete(url, { headers });
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

export const generateLinks = async (data) => {
  try {
    const response = await axiosInstance.post("/affiliate_links", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteLinks = async (id) => {
  const url = `${BASE_URL}/affiliate_links/${id}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.delete(url, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const CheckKyc = async () => {
  const url = `${BASE_URL}/kyc/check`;
  const headers = { Authorization: `Bearer ${token}` };

  try {
    const response = await fetch(url, { headers });
    const data = response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};
