import React, { useState, useRef } from "react";
import styles from "./Register.module.css";
import icon1 from "../../assets/Broker-Icon.svg";
import icon2 from "../../assets/Investor-Icon.svg";
import { Link, useSearchParams } from "react-router-dom";
import profile from "../../assets/profile-icon.svg";
import phone from "../../assets/phone-icon.svg";
import email from "../../assets/email-icon.svg";
import password from "../../assets/password-icon.svg";
import classnames from "classnames";
import Alert from "../../components/Alert";
import jwtDecode from "jwt-decode";
import LoaderSecondary from "../LoaderSecondary";
import imgLoading from "../../assets/loading.gif";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userRegister } from "../../utils/api";
import { Button } from "antd";
import { setCookie } from "../../utils/libs";
import FormGroup from "../FormGroup";

// todo test if broker and investor are working

const Register = () => {
  const [userType, setUserType] = useState("broker");
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [progress, setProgress] = useState(false);

  const userRole = useSelector((state) => state.userRole);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    password: "",
    company: "",
    agents: "",
    // role: `${userType}`,
    role: "broker",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProgress(true);

    if (
      formData.first_name.length === 0 ||
      formData.email.length === 0 ||
      formData.password.length === 0
    ) {
      setOpen(true);
      setSeverity("error");
      setMessage("Please fill all fields!");
      setProgress(false);
      return;
    }

    // Confirm password validation
    if (formData.password !== confirmPassword) {
      setOpen(true);
      setSeverity("error");
      setMessage("Passwords do not match!");
      setProgress(false);
      return;
    }

    const data = await userRegister(JSON.stringify(formData));

    if (data.status === "success") {
      event.preventDefault();
      setOpen(true);
      setSeverity("success");
      setMessage(`${data.message}`);
      setProgress(false);

      const token = data.access_token;
      localStorage.setItem("token", token);
      localStorage.setItem("username", data.username);

      const decodedToken = jwtDecode(data.access_token);
      localStorage.setItem("userRole", decodedToken.UserInfo.roles);
      dispatch({ type: "userInfoRole", userRole: decodedToken.UserInfo.roles });
      navigate("/");
    } else if (data.status === "fail") {
      setOpen(true);
      setProgress(false);
      event.preventDefault();
      setSeverity("error");
      setMessage(`${data.message}`);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUserType = (event) => {
    setUserType(event.target.value);
  };

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handlePrivacy = (event) => {
    setIsChecked(event.target.checked);
  };

  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleFocus = (index) => {
    inputRefs[index].current.classList.add(styles.focused);
  };

  const handleBlur = (index) => {
    inputRefs[index].current.classList.remove(styles.focused);
  };

  return (
    <>
      <div className="py-4"></div>
      <div className={styles.container}>
        <div className={styles.t1}>SIGN UP TO WEFRACTION</div>
        <div className={styles.wrapper}>
          <div className={styles.t2}>CREATE AN ACCOUNT NOW!</div>
          <form className={styles.form} onSubmit={handleSubmit}>
            <FormGroup
              handleChange={handleChange}
              state={formData.first_name}
              label="First Name"
              name="first_name"
              required
            />
            <FormGroup
              handleChange={handleChange}
              state={formData.last_name}
              label="Last Name"
              name="last_name"
              required
            />
            <FormGroup
              handleChange={handleChange}
              state={formData.phone_number}
              label="Phone Number"
              name="phone_number"
              required
            />
            <FormGroup
              handleChange={handleChange}
              state={formData.email}
              label="Email"
              name="email"
              type="email"
              required
            />
            <FormGroup
              handleChange={handleChange}
              state={formData.password}
              type="password"
              label="Password"
              name="password"
              required
            />
            <FormGroup
              handleChange={handleChangeConfirmPassword}
              type="password"
              state={confirmPassword}
              label="Confirm Password"
              name="confirmPassword"
              required
            />
            <FormGroup
              state={formData.company}
              handleChange={handleChange}
              name="company"
              label="Company Name"
              required
            />
            <FormGroup
              state={formData.agents}
              handleChange={handleChange}
              name="agents"
              label="Number of Agents"
              required
            />

            <label className={styles.privacyContainer}>
              <input type="checkbox" required onChange={handlePrivacy} />I
              accept the Terms, Privacy Policy, and Cookie Policy.
            </label>

            <button
              type="submit"
              disabled={!isChecked || progress}
              className={styles.btnCreate}
            >
              Create Account
              {progress && <LoaderSecondary />}
            </button>
            <div className={styles.orLogIn}>
              <span>
                ALREADY HAVE AN ACCOUNT?{"  "}
                <span
                  className={styles.green}
                  onClick={() => navigate("/login")}
                >
                  LOGIN HERE
                </span>
              </span>
            </div>
          </form>
        </div>
      </div>
      <div>
        <Alert
          message={message}
          open={open}
          setOpen={setOpen}
          severity={severity}
        ></Alert>
      </div>
    </>
  );
};

export default Register;
