import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Register from "./components/Register/Register";
import { useEffect } from "react";
import Login from "./components/Login/Login";
import Properties from "./components/Property/Properties";
import AffiliateLink from "./components/AffiliateLink/AffiliateLink";
import LandingPage from "./components/LandingPage/LandingPage";
import List from "./components/List/List";
import AdminPage from "./components/AdminPage/AdminPage";
import AssetInvestor from "./components/AssetInvestor/AssetInvestor";
// Do not remove
import axiosInterceptors from "./Axios/Interceptors";
import HeaderLayout from "./components/Protected/HeaderLayout";
import Protected from "./components/Protected/Protected";
import SideBarLayout from "./components/Protected/SideBarLayout";
import NoLoggedIn from "./components/Protected/NoLoggedIn";
import InvestorOnlyRegister from "./components/AdminPage/InvestorOnlyRegister";
import KYCPage from "./components/KYCPage/KYCPage";
import NotFound from "./components/NotFound/NotFound";

function App() {
  window.Beacon("init", "3aaf3feb-ef45-480f-a54c-636a396a0a81");
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<HeaderLayout />}>
            <Route element={<SideBarLayout />}>
              <Route path="/list" element={<List />} />
              {/* <Route path="/" element={<List />} /> */}
            </Route>
            <Route element={<NoLoggedIn />}>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<InvestorOnlyRegister />} />
              <Route path="/brokerRegister" element={<Register />} />
            </Route>
            <Route element={<SideBarLayout />}>
              <Route path="/" element={<LandingPage />} />
              <Route path="/project" element={<LandingPage />} />
            </Route>
            <Route element={<Protected allowedRole={"admin"} />}>
              <Route element={<SideBarLayout />}>
                <Route path="/admin" element={<AdminPage />} />
              </Route>
            </Route>
            <Route element={<Protected allowedRole={"investor"} />}>
              <Route path="/kyc" element={<KYCPage />} />
              <Route element={<SideBarLayout />}>
                <Route path="/myassets" element={<AssetInvestor />} />
              </Route>
            </Route>
            <Route element={<Protected allowedRole={"broker"} />}>
              <Route element={<SideBarLayout />}>
                <Route path="/affiliate" element={<AffiliateLink />} />
                <Route path="/submit-property" element={<Properties />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
